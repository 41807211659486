import React, { useState } from "react";
import Switch from "react-input-switch";

function Toggle({ Show, name }) {
  const [value, setValue] = useState(0);

  // console.log(value);

  return (
    <div>
      <Switch
        name={name}
        value={value}
        onChange={() => {
          if (Show) {
            Show(value === 0 ? 1 : 0);
          }
          setValue(value === 0 ? 1 : 0);
        }}
        styles={{
          track: {
            backgroundColor: "#A3A3A3",
          },
          trackChecked: {
            backgroundColor: "#00D292",
          },

          button: {
            backgroundColor: "white",
          },
          buttonChecked: {
            backgroundColor: "white",
          },
        }}
      />
    </div>
  );
}

export default Toggle;
