import React from 'react'
import '../Cafe/Counter.css'
import './Settings.css'
import Select from 'react-select';

const Users = [
    {
      value: '01',
      label: 'Kitchen'
    },
    {
      value: '02',
      label: 'Admin'
    },
    {
      value: '03',
      label: 'Cafe'
    }
  ]

const General = () => {


  return (
    
    <div className="Contain">

        <div className='dblsections'>
            <div className='fieldss'>
                <label for="ItemN">Store Name</label>
                <input type="text" id="ItemN" placeholder='Store Name' />
            </div > 
            <div className='fieldss'>
                            <h1 className='userTitle'>Time Zone</h1>
                            <Select 
                            autosize={true}
                            options={Users}
                            components={{
                            IndicatorSeparator: () => null
                            }}
                            placeholder="Time Zone"
                            theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#FFD39E',
                                primary: '#FF8C00',
                                primary50: '#FFD39E'
                            },
                            })}
                            />
             </div> 
             <div className='fieldss'>
                            <h1 className='userTitle'>Currency Selection</h1>
                            <Select 
                            autosize={true}
                            options={Users}
                            components={{
                            IndicatorSeparator: () => null
                            }}
                            placeholder="Dollar $"
                            theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#FFD39E',
                                primary: '#FF8C00',
                                primary50: '#FFD39E'
                            },
                            })}
                            />
             </div>
             <div className='fieldss'>
                            <h1 className='userTitle'>Currency Symbol Alignment</h1>
                            <Select 
                            autosize={true}
                            options={Users}
                            components={{
                            IndicatorSeparator: () => null
                            }}
                            placeholder="Left"
                            theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#FFD39E',
                                primary: '#FF8C00',
                                primary50: '#FFD39E'
                            },
                            })}
                            />
             </div>
             <div className='fieldss'>
                <label for="ItemN">Bag Price</label>
                <input type="text" id="ItemN" placeholder='25$' />
            </div > 
        </div>
        <div className="setbtn"> 
            <button className='Savebtn'>
                <h6 className='Countertext'>Save Update</h6>
            </button>
        </div>
    </div>
  )
}

export default General;