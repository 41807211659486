import React from "react";

function items() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#FFDEDE"></circle>
      <path
        stroke="red"
        strokeLinejoin="round"
        strokeWidth="0.8"
        d="M18 9l-6-3-6 3v6l6 3 6-3V9z"
      ></path>
      <path
        stroke="red"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.8"
        d="M15 7.5l-6 3M6 9l6 3-6-3zm6 9v-6 6zm6-9l-6 3 6-3z"
      ></path>
    </svg>
  );
}

export default items;
