import React from "react";

function TableManagment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#A3A3A3"
        d="M9 16.5H4.5A1.5 1.5 0 016 15V6H1.5V3.75H12V6H7.5v9A1.5 1.5 0 019 16.5zm7.5-15v15H15v-5.25h-3.75v5.25h-1.5v-6a1.5 1.5 0 011.5-1.5H15V1.5h1.5z"
      ></path>
    </svg>
  );
}

export default TableManagment;