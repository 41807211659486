import React from "react";

function TAicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
      viewBox="0 0 41 41"
    >
      <circle
        cx="20.5"
        cy="20.5"
        r="19.75"
        stroke="#F6951F"
        strokeWidth="1.5"
      ></circle>
      <path
        fill="#FFEBD2"
        d="M11.436 21.153c.176-.763 1.057-4.403 1.614-5.518-.616-.382-1.086-1.145-.968-1.82.094-.54.685-.773.968-.822h4.726l.352-.558 3.052-1.379h7.866v-.734h2.73v4.579h-2.73l-.322-.91h-1.057l-.176.499-.646.793-1.291.733 1.555 5.137v10.42l-15.673-.206-.264-2.76c.03-2.23.123-6.844.264-7.454z"
      ></path>
      <path
        fill="#fff"
        d="M18.154 24.318c-.116-1.576.896-2.69 1.417-3.05h.72c.064 2.081.192 6.283.192 6.436 0 .154-.128.577-.192.769l-.216.456-.504.12-.48-.12-.505-.312v-3.53l-.432-.24v-.53zM14.677 24.363c-.115-1.576-.263-1.707.943-1.832l.932.41c.064 2.08.192 4.611.192 4.765 0 .154-.128.576-.192.769l-.216.456-.505.12-.48-.12-.674-.337.17-3.506-.294-.228.124-.497z"
      ></path>
      <path
        fill="#F6951F"
        d="M20.5 25.462v-4.204a.352.352 0 00-.352-.352c-2.148 0-2.45 3.687-2.462 3.844a.352.352 0 00.194.341l.51.255v.117l-.273 2.727a1.055 1.055 0 001.05 1.16h.556a1.055 1.055 0 001.05-1.16l-.273-2.728zm-2.087-.891c.061-.493.17-.98.324-1.452.27-.796.626-1.28 1.06-1.445v3.454h-.704a.351.351 0 00-.195-.314l-.485-.243zm1.57 3.96a.348.348 0 01-.26.116h-.556a.353.353 0 01-.35-.387l.242-2.428h.771l.243 2.427a.347.347 0 01-.09.272z"
      ></path>
      <path
        fill="#F6951F"
        d="M31.758 10h-2.815a.352.352 0 00-.351.352v.352h-7.346c-.318 0-.63.08-.91.232l-3.445 1.879H12.76c-.592 0-1.055.618-1.055 1.407 0 .737.404 1.324.939 1.399l-1.587 5.37a1.758 1.758 0 00-.056.441v10.03a.352.352 0 00.352.352h15.832a.352.352 0 00.352-.352v-10a1.76 1.76 0 00-.071-.497l-1.466-4.984a1.904 1.904 0 001.884-1.759h.709v.704a.352.352 0 00.351.351h2.815a.351.351 0 00.352-.351v-4.574a.352.352 0 00-.352-.352zm-19.35 4.222c0-.43.208-.704.351-.704h10.058l-1.408 1.408h-8.65c-.143 0-.352-.274-.352-.704zm10.959 6.783c-.035.14-.053.283-.053.427v9.678h-11.61v-9.678c0-.084.01-.168.03-.25l1.64-5.553h7.399a1.55 1.55 0 002.282 2.016l1.693-1.317-1.378 4.663a.384.384 0 00-.003.014zm2.058 9.195l.91.91h-1.82l.91-.91zm1.364-9.036c.029.096.043.196.043.297v9.151l-1.055-1.055v-7.595a.352.352 0 10-.704 0v7.595l-1.055 1.055v-9.18c0-.084.01-.168.03-.25l1.376-4.66 1.366 4.642zm1.803-7.646h-.846a.563.563 0 00-.562.562 1.199 1.199 0 01-1.197 1.197h-.865a.496.496 0 00-.303.105l-2.196 1.707a.846.846 0 01-1.117-1.265l2.409-2.409a.352.352 0 00-.25-.6h-5.304l2.312-1.261c.176-.096.373-.146.573-.147h7.346v2.111zm2.814 1.056h-2.11v-3.87h2.11v3.87z"
      ></path>
      <path
        fill="#F6951F"
        d="M17.333 21.258a.352.352 0 00-.704 0v1.056h-.704v-1.056a.352.352 0 10-.703 0v1.056h-.704v-1.056a.352.352 0 00-.704 0v1.407h.001c0 .055.012.109.037.158l.66 1.32-.291 4.076a1.056 1.056 0 001.053 1.131h.6a1.055 1.055 0 001.052-1.13l-.29-4.076.66-1.321a.35.35 0 00.036-.158v-1.407zm-.921 1.76l-.352.703h-.973l-.351-.704h1.676zm-.281 5.516a.347.347 0 01-.258.113h-.6a.352.352 0 01-.35-.377l.274-3.845h.753l.274 3.845a.347.347 0 01-.093.264z"
      ></path>
    </svg>
  );
}

export default TAicon;
