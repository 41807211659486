import Search from "../../../../Svg/Search";
import Plus from "../../../../Svg/Plus";
import "./../Itemslist/Itemslist.css";
import Orderno from "../../../../Svg/Orderno";
import EditIcon from "../../../../Svg/EditIcon";
import DeleteIcon from "../../../../Svg/DeleteIcon";
import Toggle from "../../UsersManagment/Toggle";
import { useState } from "react";
import AddDeals from "./AddDeals";
import Dealsdata from "./Dealsdata";

const Dealdata = [
  {
    image: "./Imagesfood/Food1.png",
    DName: "2x burger and 2 Drinks",
    Type: "Fast Food",
    Nitems: "4",
    Status: "Active",
    Price: "48KR",
    Action: "Ready",
  },
];

function Deals({ itemList, deals, kioskId }) {
  const [OpenModal6, setOpenModal6] = useState(false);
  console.log("dealssssss", deals);
  return (
    <div className="itemslist">
      <div className="top-bar">
        <div className="custom-search">
          <input type="search" placeholder="Search Anything" />
          <i className="Iconic">
            <Search />
          </i>
        </div>
        <div>
          <button
            className="tablebutton"
            onClick={() => {
              setOpenModal6(true);
            }}
          >
            <Plus />
            <h6 className="tabletext"> Add New Deals</h6>
          </button>
        </div>
      </div>
      <div className="tabi">
        <div className="Table-top">
          <div className="Table-head">
            <Orderno />
          </div>
          <div className="Table-head" style={{ justifyContent: "flex-start" }}>
            <h5>Deals Name</h5>
          </div>
          <div className="Table-head">
            <h5>Number Items</h5>
          </div>
          <div className="Table-head">
            <h5>Status</h5>
          </div>
          <div className="Table-head">
            <h5>Price</h5>
          </div>
          <div className="Table-head">
            <h5>Action</h5>
          </div>
        </div>
        {deals.map((val, key) => {
          return (
            <div className="App1">
              <div key={key} className="Table">
                <Dealsdata
                  image={val.image}
                  DName={val.name}
                  Nitems={val?.items.length}
                  Status={val.Status}
                  Price={val.price}
                />
              </div>
            </div>
          );
        })}
      </div>
      {OpenModal6 && (
        <AddDeals
          itemList={itemList}
          kioskId={kioskId}
          closeModal={setOpenModal6}
        />
      )}
    </div>
  );
}

export default Deals;
