import React, { useState, useEffect } from "react";
import Closebtn from "../../../Svg/Closebtn";
import "../Cafe/Modal.css";
import { Bars } from "react-loader-spinner";

const options = ["Kiosk 1", "Kiosk 2", "Kiosk 3"];

function CloneKiosk({ closeModal }) {
  const [kioskData, setKioskData] = useState({
    Kiosk: "",
    CloneTo: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setKioskData({ ...kioskData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(kioskData);
    setIsLoading(true);
    // validate form data and submit to server or database
  };

  useEffect(() => {
    if (isLoading) {
      // simulate a long loading process
      setTimeout(() => {
        closeModal(false);
        setIsLoading(false);
      }, 3000);
    }
  }, [isLoading]);

  return (
    <div className="Modalbg">
      <div className="ModalContainer" style={{ width: "350px" }}>
        <div className="closebtn">
          <button onClick={() => closeModal(false)}>
            <Closebtn />
          </button>
        </div>
        <div className="ModalTitle">Clone the Kiosk</div>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <div className="Modalbtn ct dr">
              <Bars
                height="50"
                width="50"
                color="#FF8C00"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <p className="loadtlt">Cloning Kiosk</p>
            </div>
          ) : (
            <div>
              <div className="Modalbody">
                <div className="fields">
                  <div className="selectdiv ">
                    <label htmlFor="role">
                      Select Kiosk
                      <select
                        name="Kiosk"
                        value={kioskData.Kiosk}
                        onChange={handleChange}
                        required="required"
                      >
                        <option value="" disabled>
                          Select Kiosk
                        </option>
                        {options.map((opt, key) => (
                          <option key={key}>{opt}</option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
                <div className="fields">
                  <div className="selectdiv ">
                    <label htmlFor="role">
                      Clone to
                      <select
                        name="CloneTo"
                        value={kioskData.CloneTo}
                        onChange={handleChange}
                        required="required"
                      >
                        <option value="" disabled>
                          Select Another Kiosk
                        </option>
                        {options.map((opt, key) => (
                          <option key={key}>{opt}</option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
              </div>
              <div className="Modalbtn">
                <button
                  id="Cancel"
                  className="btn"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn">
                  Clone
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default CloneKiosk;
