import React, { useState } from "react";
import Select from "react-select";
import Kiosklist from "../../../Svg/Kiosklist";

const placeholderKiosk = (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
    <Kiosklist />
    <span style={{ marginLeft: 10 }}>Kiosk Selection</span>
  </div>
);

function Kioskselection({ kiosksList, selectedKiosk, selectKiosk }) {
  const selections = [
    {
      value: 1,
      text: "Kiosk 1",
      icon: <Kiosklist />,
    },
    {
      value: 2,
      text: "Kiosk 2",
      icon: <Kiosklist />,
    },
    {
      value: 3,
      text: "Kiosk 3",
      icon: <Kiosklist />,
    },
    {
      value: 4,
      text: "Kiosk 4",
      icon: <Kiosklist />,
    },
  ];
  // handle onChange event of the dropdown
  const handleChange = (e) => {
    selectKiosk(e);
    // setSelectedOptions(e);
  };

  return (
    <div className="List">
      <Select
        placeholder={placeholderKiosk}
        autosize={true}
        value={selectedKiosk}
        options={kiosksList}
        onChange={handleChange}
        components={{
          IndicatorSeparator: () => null,
        }}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {e.icon}
            <span style={{ marginLeft: 10 }}>{e.deviceName}</span>
          </div>
        )}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#FFD39E",
            primary: "#FF8C00",
            primary50: "#FFD39E",
          },
        })}
      />
    </div>
  );
}

export default Kioskselection;
