import React from "react";

function Notification() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="29"
      fill="none"
      viewBox="0 0 27 29"
    >
      <path
        fill="url(#paint0_linear_215_6693)"
        d="M4.5 9.5C5.3 4.3 10.5 3 13 3c3.5 0 2.5.5 5.5 2 2.4 1.2 3.333 4.5 3.5 6v5l3 3v3H1v-2l.5-1.5 2.5-3c-.166.167-.3-.8.5-6z"
      ></path>
      <path
        fill="url(#paint1_linear_215_6693)"
        d="M25.707 18.293L23 15.586V12a10.014 10.014 0 00-9-9.95V0h-2v2.05A10.014 10.014 0 003 12v3.586L.293 18.293A1 1 0 000 19v3a1 1 0 001 1h7v.777a5.152 5.152 0 004.5 5.199A5.007 5.007 0 0018 24v-1h7a1 1 0 001-1v-3a1 1 0 00-.293-.707zM16 24a3 3 0 01-6 0v-1h6v1zm8-3H2v-1.586l2.707-2.707A1 1 0 005 16v-4a8 8 0 1116 0v4a1 1 0 00.293.707L24 19.414V21z"
      ></path>
      <path
        fill="url(#paint2_linear_215_6693)"
        stroke="#FAF9FB"
        strokeWidth="1.5"
        d="M21 11.75a4.75 4.75 0 100-9.5 4.75 4.75 0 000 9.5z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_215_6693"
          x1="13"
          x2="13"
          y1="3"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEBD2"></stop>
          <stop offset="1" stopColor="#FBB3A9"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_215_6693"
          x1="13"
          x2="13"
          y1="0"
          y2="29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8B00"></stop>
          <stop offset="1" stopColor="#DE0100"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_215_6693"
          x1="21"
          x2="21"
          y1="3"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8B00"></stop>
          <stop offset="1" stopColor="#DE0100"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Notification;