import React from "react";

function Cafe() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    fill="none"
    viewBox="0 0 18 16"
  >
    <path
      stroke="#A3A3A3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M1 15h14M13.667 1h2.666a.667.667 0 01.667.667v1.416A1.916 1.916 0 0115.083 5h-1.416V1zM2.333 1h11.334v8a3.334 3.334 0 01-3.334 3.333H5.667A3.333 3.333 0 012.333 9V1z"
    ></path>
  </svg>
  );
}

export default Cafe;