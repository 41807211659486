import React from "react";
import "./../Itemslist/Itemslist.css";
import EditIcon from "../../../../Svg/EditIcon";
import DeleteIcon from "../../../../Svg/DeleteIcon";
import Toggle from "../../UsersManagment/Toggle";

function Extrasdata({ image, ename, eprice }) {
  console.log("extraimage", image);
  return (
    <div className="Table">
      <div className="Table-head">
        <img src={`http://159.69.211.133:3000/${image}`} alt="" />
      </div>
      <div className="Table-head" style={{ justifyContent: "flex-start" }}>
        <p className="title-u">{ename}</p>
      </div>
      <div className="Table-head">
        <p className="title-u">{eprice}</p>
      </div>
      <div className="Table-head" id="Tags">
        <div
        //   onClick={() => {
        //     setOpen4(true);
        //     setselecteduser(item);
        //   }}
        //   className="ico-edit pointer flex aic jc"
        >
          <EditIcon />
        </div>
        <div
        //   onClick={() => {
        //     setOpen3(true);
        //     setusertobedelete(item);
        //   }}
        //   className="icon-del flex aic"
        >
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
}

export default Extrasdata;
