import React, { useState } from "react";
import Header from "../../Header/Header";
import "./Items.css";
import Itemslist from "./Itemslist/Itemslist";
import Kioskselection from "./Kioskselection";
import Category from "./Category/Category";
import Deals from "./Deals/Deals";
import Sides from "./Sides/Sides";
import Extras from "./Extras/Extras";
import Sidebar from "../../Sidebar/Sidebar";
import Ingredients from "./Ingredients/Ingredients";
import { useDispatch, useSelector } from "react-redux";
import Kiosklist from "../../../Svg/Kiosklist";
import { getKioskData } from "../../../redux/Actions/action";
import { useEffect } from "react";
import { itemAction } from "../../../redux/Actions/ItemAction";

const Items = () => {
  const [ToggleTable, setToggleTable] = useState(1);
  const OrderTab = (index) => {
    setToggleTable(index);
  };
  const { branches, error } = useSelector((state) => state.branchData);

  const { extras, ingredients } = useSelector(
    (state) => state.MerchantKitchenData
  );
  console.log("extras FROM ", extras, "ingredients FROM", ingredients);
  const { side, categories, deal, items, loading } = useSelector(
    (state) => state.kioskData
  );
  const selections = [
    {
      value: 1,
      text: "Kiosk 1",
      icon: <Kiosklist />,
    },
    {
      value: 2,
      text: "Kiosk 2",
      icon: <Kiosklist />,
    },
    {
      value: 3,
      text: "Kiosk 3",
      icon: <Kiosklist />,
    },
    {
      value: 4,
      text: "Kiosk 4",
      icon: <Kiosklist />,
    },
  ];

  const dispatch = useDispatch();
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedKiosk, setSelectedKiosk] = useState(null);
  useEffect(() => {
    setSelectedKiosk(null);
  }, [selectedBranch]);
  useEffect(() => {
    if (selectedKiosk?.id) {
      dispatch(getKioskData(selectedKiosk?.id));
    }
    // dispatch(itemAction(selectedKiosk?.id));
  }, [selectedKiosk]);
  return (
    <div className="AppBack">
      <div className="Sider">
        <Sidebar />
      </div>
      <div className="Container">
        <Header
          title="Kiosk Items"
          branchList={branches}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
        />
        <div className="MainItems">
          <div className="ITop">
            <Kioskselection
              kiosksList={selectedBranch?.kiosks}
              selectedKiosk={selectedKiosk}
              selectKiosk={setSelectedKiosk}
            />
            <div>
              <div className="Toggle-table">
                <button
                  className={ToggleTable === 1 ? "All Activn" : "All"}
                  onClick={() => OrderTab(1)}
                >
                  Items
                </button>
                <button
                  className={ToggleTable === 2 ? "Take Activn" : "Take"}
                  onClick={() => OrderTab(2)}
                >
                  Category
                </button>
                <button
                  className={ToggleTable === 3 ? "Pick Activn" : "Pick"}
                  onClick={() => OrderTab(3)}
                >
                  Deals
                </button>
                <button
                  className={ToggleTable === 4 ? "Dine Activn" : "Dine"}
                  onClick={() => OrderTab(4)}
                >
                  Sides
                </button>
                <button
                  className={ToggleTable === 5 ? "Dine Activn" : "Dine"}
                  onClick={() => OrderTab(5)}
                >
                  Extras
                </button>
                <button
                  className={ToggleTable === 6 ? "Dine Activn" : "Dine"}
                  onClick={() => OrderTab(6)}
                >
                  Ingredients
                </button>
              </div>
            </div>
          </div>
          <div className={ToggleTable === 1 ? "section activess" : "section"}>
            <Itemslist
              itemList={items}
              kioskId={selectedKiosk?.id}
              extras={extras}
              Ingredients={ingredients}
              sides={side}
              categories={categories}
            />
          </div>
          <div className={ToggleTable === 2 ? "section activess" : "section"}>
            <Category categories={categories} kioskId={selectedKiosk?.id} />
          </div>
          <div className={ToggleTable === 3 ? "section activess" : "section"}>
            <Deals itemList={items} deals={deal} kioskId={selectedKiosk?.id} />
          </div>
          <div className={ToggleTable === 4 ? "section activess" : "section"}>
            <Sides sides={side} kioskId={selectedKiosk?.id} itemList={items} />
          </div>
          <div className={ToggleTable === 5 ? "section activess" : "section"}>
            <Extras kioskId={selectedKiosk?.id} />
          </div>
          <div className={ToggleTable === 6 ? "section activess" : "section"}>
            <Ingredients kioskId={selectedKiosk?.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Items;
