import React from "react";

function Ordercl() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#DEFFF1"></circle>
      <path
        fill="#00E384"
        d="M17.542 7.334h-1.264v.778h1.167v8.555H6.555V8.112h1.167v-.778H6.458a.692.692 0 00-.68.704v8.703a.692.692 0 00.68.704h11.084a.692.692 0 00.68-.704V8.038a.694.694 0 00-.68-.704z"
      ></path>
      <path
        fill="#00E384"
        d="M8.111 10.445h.778v.778H8.11v-.778zM10.444 10.445h.778v.778h-.777v-.778zM12.778 10.445h.778v.778h-.778v-.778zM15.111 10.445h.778v.778h-.778v-.778zM8.111 12.389h.778v.777H8.11v-.777zM10.444 12.389h.778v.777h-.777v-.777zM12.778 12.389h.778v.777h-.778v-.777zM15.111 12.389h.778v.777h-.778v-.777zM8.111 14.334h.778v.778H8.11v-.778zM10.444 14.334h.778v.778h-.777v-.778zM12.778 14.334h.778v.778h-.778v-.778zM15.111 14.334h.778v.778h-.778v-.778zM8.889 8.888a.389.389 0 00.389-.388V6.166a.389.389 0 10-.778 0V8.5a.389.389 0 00.389.388zM15.111 8.888A.389.389 0 0015.5 8.5V6.166a.389.389 0 10-.778 0V8.5a.389.389 0 00.39.388zM10.056 7.334h3.888v.778h-3.889v-.778z"
      ></path>
    </svg>
  );
}

export default Ordercl;
