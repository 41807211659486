import {
  GET_KIOSK_DATA_FAIL,
  GET_KIOSK_DATA_SUCCESS,
} from "../Actions/Constants";

const initialState = {
  side: [],
  deal: [],
  categories: [],
  items: [],
  error: false,
  loading: true,
};
export const kioskData = (state = initialState, action) => {
  let { type, payload } = action;
  switch (type) {
    case GET_KIOSK_DATA_SUCCESS:
      // console.log("payload", payload.categories);
      let items = [];
      payload?.categories.map((data) => {
        items.push(...data.items);
        console.log("map data", data.items);
      });
      console.log("filteredItems", items);
      return {
        ...state,
        side: [...payload.side],
        deal: [...payload.deal],
        categories: [...payload.categories],
        items: [...items],
        loading: false,
      };
    case GET_KIOSK_DATA_FAIL:
      return {
        ...state,
        error: true,
        side: [],
        deal: [],
        categories: [],
        items: [],
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
