import { act } from "react-dom/test-utils";
import { ITEM_LIST_FAIL, ITEM_LIST_SUCCESS } from "../Actions/Constants";
const initialState = {
  items: [],
  error: false,
  loading: true,
};
export const itemData = (state = initialState, action) => {
  let { type, payload } = action;
  //   console.log("payloaddddddddddddddddddddddddd", payload);
  switch (action.type) {
    case ITEM_LIST_SUCCESS:
      //   console.log("payload=>", payload);
      return {
        ...state,
        items: [...payload],
        loading: false,
      };
    case ITEM_LIST_FAIL:
      console.log("hello from fail");
      return {
        ...state,
        items: [],
        error: true,
      };
    default:
      console.log("hello fromd efault");
      return {
        ...state,
        error: true,
      };
  }
};
