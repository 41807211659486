import React, { useState } from "react";
import "./Sidebar.css";
import Logo from "../../imgs/logo.png";
import Logo2 from "../../imgs/logo2.png";
import Vector from "../../imgs/Vector.png";
import Dashboard from "../../Svg/Dashboard";
import { SidebarData } from "../../data/data";
import { NavLink, Link, useParams } from "react-router-dom";

const Sidebar = (props) => {
  const [selected, setSelected] = useState(0);
  const path = window.location.pathname;
  console.log("params", window.location.pathname);

  return (
    <div className="Sidebar ">
      {/*logo*/}
      <div className="logo">
        <img src={Logo} alt="" srcSet="" />
        <img className="logo2" src={Logo2} alt="" srcSet="" />
        {/* <img className="Arrowicon" src={Vector} alt="" srcSet="" /> */}
      </div>

      {/*Menu*/}
      <div className="menu">
        {SidebarData.map((item, index) => {
          return (
            <NavLink
              exact
              to={item.slug}
              className={item.slug === path ? "menuitems active" : "menuitems"}
              key={index}
              onClick={() => setSelected(item.slug)}
            >
              <i
                exact
                to={item.slug}
                className={item.slug === path ? "icon activated" : "icon"}
              >
                {" "}
                {item.icon}{" "}
              </i>

              <span className="menutlt"> {item.heading}</span>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
