import React from "react";
import "./Banner.css";
import Sets from "../../../Svg/Sets";
import Settings from "../../../Svg/Settings";
import Header from "../../Header/Header";
import Kioskselection from "../KioskItems/Kioskselection";
import { useState } from "react";
import AddSlide from "../../../Svg/AddSlide";
import Sdelete from "../../../Svg/Sdelete";
import Sidebar from "../../Sidebar/Sidebar";

const data9 = [
  {
    index: 0,
    image: "./Imagesfood/Rectangle-1.png",
  },
  {
    index: 1,
    image: "./Imagesfood/Rectangle.png",
  },
  {
    index: 2,
    image: "./Imagesfood/Rectangle-1.png",
  },
];

function Banner() {
  const [Data, setData] = useState();
  console.log(Data);

  return (
    <div className="AppBack">
      <div className="Sider">
        {" "}
        <Sidebar />
      </div>
      <div className="Container">
        <Header title="Banners" />
        <div className="MainUsers">
          <div className="Top">
            <div className="filters">
              <Kioskselection />
            </div>
          </div>
          <div className="Maintab">
            <div className="MainTitle">
              <div className="sec">
                <i>
                  {" "}
                  <Sets />{" "}
                </i>
                <h6 className="Menucardtitle">Banner Settings</h6>
              </div>
              <div className="Sliders">
                <h3 className="Stitle">Slide Show (size 400x200)</h3>
                <div className="Slide">
                  {data9.map((val, key) => {
                    return (
                      <div key={key} className="sld">
                        <img className="slideimage" src={val.image} alt="" />
                        <i className="Sdelete">
                          {" "}
                          <Sdelete />{" "}
                        </i>
                        <div className="radiobtn">
                          <input
                            type="radio"
                            value={val.index}
                            name="radiovalues"
                          />
                          <p>Make Primary</p>
                        </div>
                      </div>
                    );
                  })}

                  <div className="main">
                    <div>
                      <label htmlFor="imgs">
                        <div className="addmore">
                          <i>
                            {" "}
                            <AddSlide />{" "}
                          </i>
                          <p>Add More</p>
                        </div>
                      </label>
                    </div>
                    <input
                      className="inp"
                      id="imgs"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(e) => setData(e.target.files)}
                    />
                  </div>
                </div>
                <h3 className="Stitle">Inner Slider (size 500x100)</h3>
                <div className="Slide">
                  {data9.map((val, key) => {
                    return (
                      <div key={key} className="sld">
                        <img className="slideimage" src={val.image} alt="" />
                        <i className="Sdelete">
                          {" "}
                          <Sdelete />{" "}
                        </i>
                        <div className="radiobtn">
                          <input
                            type="radio"
                            value={val.index}
                            name="radiovalues"
                          />
                          <p>Make Primary</p>
                        </div>
                      </div>
                    );
                  })}

                  <div className="main">
                    <div>
                      <label htmlFor="imgs">
                        <div className="addmore">
                          <i>
                            {" "}
                            <AddSlide />{" "}
                          </i>
                          <p>Add More</p>
                        </div>
                      </label>
                    </div>
                    <input
                      className="inp"
                      id="imgs"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(e) => setData(e.target.files)}
                    />
                  </div>
                </div>
                <h3 className="Stitle">
                  Inner Slider Handicap Mode (size 400x340)
                </h3>
                <div className="Slide">
                  {data9.map((val, key) => {
                    return (
                      <div key={key} className="sld">
                        <img className="slideimage" src={val.image} alt="" />
                        <i className="Sdelete">
                          {" "}
                          <Sdelete />{" "}
                        </i>
                        <div className="radiobtn">
                          <input
                            type="radio"
                            value={val.index}
                            name="radiovalues"
                          />
                          <p>Make Primary</p>
                        </div>
                      </div>
                    );
                  })}

                  <div className="main">
                    <div>
                      <label htmlFor="imgs">
                        <div className="addmore">
                          <i>
                            {" "}
                            <AddSlide />{" "}
                          </i>
                          <p>Add More</p>
                        </div>
                      </label>
                    </div>
                    <input
                      className="inp"
                      id="imgs"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(e) => setData(e.target.files)}
                    />
                  </div>
                </div>
              </div>
              <div className="btn9">
                <button className="Savebutton">
                  <h6 className="Countertext">Save Update</h6>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
