import React from "react";

function Plus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
        d="M9 17V9m0 0V1m0 8h8M9 9H1"
      ></path>
    </svg>
  );
}

export default Plus;
