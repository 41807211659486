import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import {
  ITEM_LIST_FAIL,
  ITEM_LIST_PENDING,
  ITEM_LIST_SUCCESS,
} from "../Actions/Constants";
function* getItems(action) {
  console.log("from saga", action.kiosk);
  // console.log("kiosk id", action.kiosk.id);
  let data = yield axios.get(`http://159.69.211.133:3000/${action.kiosk}`);

  if (data.status === 200) {
    yield put({ type: ITEM_LIST_SUCCESS, payload: data.data });
  } else {
    yield put({ type: ITEM_LIST_FAIL });
  }
}
function* itemSaga() {
  yield takeEvery(ITEM_LIST_PENDING, getItems);
}
export default itemSaga;
