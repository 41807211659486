import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      fill="none"
      viewBox="0 0 12 16"
    >
      <path
        stroke="#A3A3A3"
        d="M10.79 14.624a.357.357 0 00.71-.053V.872l-.004-.068A.357.357 0 0011.143.5l-.353 14.124zm0 0l-.004-.068v-4.77H9.429s0 0 0 0a.357.357 0 01-.354-.304l-.004-.068V4.286c0-.848.293-1.834.737-2.603.221-.383.468-.69.713-.897.247-.209.458-.286.622-.286l-.353 14.124zM3.782.804a.357.357 0 00-.71.054s0 0 0 0v6.635l-.75-.433a2.214 2.214 0 01-1.108-1.917v0-4.27L1.21.804A.357.357 0 00.5.857v4.28l.004.163a2.93 2.93 0 002.193 2.68l.374.096v6.481l.004.067a.357.357 0 00.71-.053s0 0 0 0V8.076l.376-.097a2.93 2.93 0 002.196-2.836s0 0 0 0V.873L6.353.803A.357.357 0 006 .5L3.782.805zm0 0l.004.068v6.622l.75-.434a2.214 2.214 0 001.1-1.73v-.022l.006-.144V.857A.357.357 0 016 .5L3.782.805z"
      ></path>
    </svg>
  );
}

export default Icon;
