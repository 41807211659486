import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import {
  GET_BRANCHES_FAIL,
  GET_BRANCHES_PENDING,
  GET_BRANCHES_SUCCESS,
  GET_USER_FAIL,
  GET_USER_PENDING,
  GET_USER_SUCCESS,
} from "../Actions/Constants";
function* getBranches() {
  let data = yield axios.get(
    `http://159.69.211.133:3000/merchants/60b1f6cf-f894-4ef2-b72f-9c19811e0e4d`
  );
  console.log("called", data);
  if (data.status === 200) {
    yield put({ type: GET_BRANCHES_SUCCESS, payload: data.data });
  } else {
    yield put({ type: GET_BRANCHES_FAIL });
  }
}
function* branchesSaga() {
  yield takeEvery(GET_BRANCHES_PENDING, getBranches);
}
export default branchesSaga;
