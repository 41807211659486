import React from 'react'
import Ordermode from '../../Svg/OrderMode';
import './Button.css'
import { NavLink, Link,useParams } from 'react-router-dom'

function Button() {
  return (
    <NavLink exact to="/Ordermode" style={{
      textDecoration: 'none'
    }}>
          <button className='Main-Button'>
             <i className="ordericon"> <Ordermode /> </i>
             <div className="title-button">Order Mode</div>
         </button>
    </NavLink>
  )
}

export default Button;