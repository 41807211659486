import React from "react";

function Setting() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.273 6.268c.474-.046.95.052 1.364.28.414.228.745.575.949.993.203.418.269.887.188 1.342a2.222 2.222 0 01-.642 1.203 2.33 2.33 0 01-1.238.624 2.367 2.367 0 01-1.38-.183 2.283 2.283 0 01-1.022-.922 2.191 2.191 0 01.377-2.69 2.34 2.34 0 011.404-.647v0zM2.717 8.5c.002.244.02.487.055.729l-1.63 1.242a.37.37 0 00-.088.482l1.542 2.594a.4.4 0 00.474.16l1.619-.633a.597.597 0 01.547.061c.247.166.506.313.776.44A.57.57 0 016.33 14l.243 1.679c.016.088.063.169.133.227.07.059.16.092.252.094h3.084a.407.407 0 00.249-.09.387.387 0 00.136-.22l.243-1.677a.555.555 0 01.107-.254.576.576 0 01.217-.174c.27-.127.529-.274.774-.44a.588.588 0 01.545-.06l1.618.633a.4.4 0 00.267.004.388.388 0 00.207-.165l1.542-2.593a.37.37 0 00-.088-.482L14.48 9.43a.565.565 0 01-.216-.493 4.66 4.66 0 000-.87.547.547 0 01.219-.49l1.377-1.05a.37.37 0 00.085-.48l-1.542-2.594a.387.387 0 00-.207-.164.4.4 0 00-.267.003l-1.619.634a.596.596 0 01-.547-.061 5.964 5.964 0 00-.776-.44A.57.57 0 0110.67 3l-.243-1.679a.386.386 0 00-.133-.227.406.406 0 00-.252-.094H6.958a.407.407 0 00-.249.09.388.388 0 00-.137.22L6.33 2.988a.555.555 0 01-.324.428c-.27.127-.529.274-.774.44a.587.587 0 01-.545.06L3.07 3.283a.4.4 0 00-.267-.003.388.388 0 00-.207.164L1.053 6.036a.37.37 0 00.088.482L2.52 7.57a.565.565 0 01.218.493c-.012.145-.02.29-.02.437z"
      ></path>
    </svg>
  );
}

export default Setting;
