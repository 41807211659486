import React from "react";

function Pickup() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="red" rx="15"></rect>
      <path
        fill="#fff"
        d="M16.902 14.783c1.435-1.088 2.411-3.034 2.461-4.882h.202c.524 0 .95-.427.95-.95v-.718A.233.233 0 0020.284 8H9.233A.233.233 0 009 8.233v.717c0 .524.426.95.95.95h.202c.05 1.85 1.026 3.795 2.461 4.883.208.157.327.4.327.667s-.119.51-.327.667c-1.435 1.088-2.41 3.034-2.46 4.882H9.95a.951.951 0 00-.95.95v.718c0 .129.104.233.233.233h11.05a.233.233 0 00.232-.233v-.718a.951.951 0 00-.95-.95h-.202c-.05-1.848-1.026-3.794-2.46-4.882a.829.829 0 01-.328-.667c0-.267.12-.51.327-.667zM9.466 8.95v-.484H20.05v.484a.485.485 0 01-.485.485H9.95a.485.485 0 01-.484-.485zm10.584 13v.484H9.466v-.485c0-.267.217-.484.484-.484h9.615c.267 0 .485.217.485.485zm-5.292-2.417c.105.068.216.126.332.175.551.23 1.064.682 1.47 1.291h-3.605c.407-.609.92-1.061 1.47-1.291.117-.049.228-.107.333-.175zm1.352-4.083c0 .413.186.792.511 1.038 1.325 1.005 2.227 2.801 2.276 4.511H17.11c-.478-.815-1.128-1.423-1.84-1.721a1.614 1.614 0 01-.28-.15v-1.082a.233.233 0 00-.465 0v1.082c-.088.059-.18.11-.279.15-.713.298-1.362.906-1.84 1.721h-1.788c.05-1.71.952-3.506 2.276-4.51a1.29 1.29 0 00.512-1.04 1.29 1.29 0 00-.512-1.038c-1.324-1.004-2.226-2.8-2.276-4.51h8.28c-.05 1.71-.952 3.506-2.277 4.51a1.29 1.29 0 00-.511 1.039zm-1.12.417v.316a.233.233 0 01-.465 0v-.316a.233.233 0 01.466 0zm2.303-4.4c-1.1-.804-1.915-.413-2.704-.035-.584.28-1.187.57-1.97.416a.233.233 0 00-.247.344c.481.842 1.147 1.474 1.874 1.778.098.04.191.09.279.15v.584a.233.233 0 00.466 0v-.584c.087-.059.18-.11.278-.15.855-.357 1.62-1.165 2.099-2.218a.233.233 0 00-.075-.285zM15.09 13.54a2.085 2.085 0 00-.332.175 2.075 2.075 0 00-.332-.175c-.517-.216-1.002-.629-1.397-1.183.696.007 1.255-.262 1.761-.505.71-.34 1.28-.613 2.07-.117-.432.86-1.07 1.513-1.77 1.805z"
      ></path>
    </svg>
  );
}

export default Pickup;
