import React from "react";
import Orderheader from "../../Header/Orderheader";
import Tableoption from "../../../Svg/Tableoption";
import "./Tab.css";

const table = [
  { title: "01" },
  { title: "02" },
  { title: "03" },
  { title: "04" },
  { title: "05" },
  { title: "06" },
  { title: "07" },
  { title: "08" },
  { title: "09" },
];

function TableHandling() {
  return (
    <div className="Container width">
      <Orderheader title="Order Mode" />
      <div className="Tableoptionss">
        <div className="tablesections">
          {table.map((tal, key) => {
            return (
              <div key={key} className="tablemanage">
                <div className="table-box">
                  <i className="tableoption-icon">
                    {" "}
                    <Tableoption />{" "}
                  </i>
                  <p className="numbering"> {tal.title} </p>
                </div>
                <button className="tablebtn">Make It Available</button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TableHandling;
