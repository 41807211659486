import { combineReducers } from "redux";
import { cartData } from "./reducer";
import { itemData } from "./ItemReducer";
import { branchData } from "./branchesReducer";
import { kioskData } from "./kioskReducer";
import { MerchantKitchenData } from "./kitchenMerchantData";
export default combineReducers({
  cartData,
  itemData,
  branchData,
  kioskData,
  MerchantKitchenData,
});
