import {
  ADD_TO_CART,
  EMPTY_CART,
  GET_BRANCHES_PENDING,
  GET_KIOSK_DATA_PENDING,
  GET_MERCHANT_KITCHEN_DATA_PENDING,
  GET_USER_PENDING,
  GET_USER_SUCCESS,
  REMOVE_FROM_CART,
} from "./Constants";

export const addtoCart = (data) => {
  return {
    // type:"REMOVE_FROM_CART",
    type: ADD_TO_CART,
    data: data,
  };
};
export const removetoCart = (data) => {
  return {
    type: REMOVE_FROM_CART,
    data: data,
  };
};
export const emptyCart = () => {
  return {
    type: EMPTY_CART,
  };
};
//for backend Integeration
export const getUser = () => {
  return {
    type: GET_BRANCHES_PENDING,
  };
};
export const getBranches = (merchantId) => {
  console.log("merchant id from actions", merchantId);
  return {
    type: GET_BRANCHES_PENDING,
    merchantId,
  };
};
export const getKioskData = (kioskId) => {
  return {
    type: GET_KIOSK_DATA_PENDING,
    kioskId,
  };
};
export const getMerchantKitchenData = (merchantId) => {
  console.log("merhcnatid", merchantId);
  return {
    type: GET_MERCHANT_KITCHEN_DATA_PENDING,
    merchantId,
  };
};
