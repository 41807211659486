import "./App.css";
import Sidebar from "./Components/Sidebar/Sidebar";
import MainDash from "./Components/Pages/Dashboard/MainDash";
import MainCafe from "./Components/Pages/Cafe/MainCafe";
import React, { useState, useEffect, useReducer } from "react";
import Loader from "./Components/Loader";
import Kitchen from "./Components/Pages/KitchenManagment/Kitchen";
// import KioskManagment from './Components/Pages/KioskManagment/KioskManagment';
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import UsersManagment from "./Components/Pages/UsersManagment/UsersManagment";
import KioskManagment from "./Components/Pages/KioskManagment/KioskManagment";
import Items from "./Components/Pages/KioskItems/Items";
import Voucher from "./Components/Pages/Voucher/Voucher";
import Banner from "./Components/Pages/Banners/Banner";
import KioskOptions from "./Components/Pages/KioskOptions/KioskOptionss";
import Settings from "./Components/Pages/Settings/Settings";
import Ordermode from "./Components/Pages/OrderMode/Ordermode";
import TableHandling from "./Components/Pages/TableMode/TableHandling";
import { useDispatch, useSelector } from "react-redux";
import { itemAction } from "./redux/Actions/ItemAction";
import {
  getBranches,
  getMerchantKitchenData,
  getUser,
} from "./redux/Actions/action";

function App() {
  const [loading, setloading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMerchantKitchenData("60b1f6cf-f894-4ef2-b72f-9c19811e0e4d"));
    dispatch(getBranches());
    // dispatch(getUser());
    setloading(false);
    setTimeout(() => {
      setloading(false);
    }, 7990);
  }, []);
  console.log("process", process.env.REACT_APP_API_PROD_KEY);
  return (
    <div className="App">
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <BrowserRouter>
          <div className="AppBack">
            <Routes>
              <Route exact path="/" element={<MainDash />} />
              <Route path="/MainCafe" element={<MainCafe />} />
              <Route path="/Kitchen" element={<Kitchen />} />
              <Route path="/UsersManagment" element={<UsersManagment />} />
              <Route path="/KioskManagment" element={<KioskManagment />} />
              <Route path="/Items" element={<Items />} />
              <Route path="/Vouchers" element={<Voucher />} />
              <Route path="/Banners" element={<Banner />} />
              <Route path="/KioskOptions" element={<KioskOptions />} />
              <Route path="/Settings" element={<Settings />} />
              <Route path="/Ordermode" element={<Ordermode />} />
              <Route path="/TableManagement" element={<TableHandling />} />
              {/* <Route exact path="/CustomerList" element={ <TableHandling /> } /> */}
            </Routes>
          </div>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
