import React from "react";

function Counter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="43"
      fill="none"
      viewBox="0 0 40 43"
    >
      <path
        fill="#F6951F"
        d="M21.429 37.389V30h-2.857v7.389l-3.695-3.695-2.02 2.02L20 42.857l7.143-7.143-2.02-2.02-3.694 3.695z"
      ></path>
      <path
        fill="#F6951F"
        d="M40 15.714H0v11.429h2.857v14.286h2.857V27.143h28.572v14.286h2.857V27.143H40V15.714zm-2.857 8.572H2.857V18.57h34.286v5.715zM18.57 5.469v7.388h2.858V5.47l3.694 3.694 2.02-2.02L20 0l-7.143 7.143 2.02 2.02 3.694-3.694z"
      ></path>
    </svg>
  );
}

export default Counter;