import React from "react";

function Amount() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#DFFFD6"></circle>
      <path
        fill="#28A900"
        d="M14.667 9.792H16c0-1.655-1.837-2.41-3.333-2.584V6h-1.334v1.208C9.837 7.382 8 8.137 8 9.792c0 1.578 1.777 2.399 3.333 2.584v2.899c-.965-.146-2-.597-2-1.4H8c0 1.51 1.617 2.403 3.333 2.588v1.204h1.334v-1.208c1.496-.174 3.333-.93 3.333-2.584 0-1.655-1.837-2.41-3.333-2.584v-2.9c.886.14 2 .55 2 1.4zm-5.334 0c0-.851 1.114-1.26 2-1.4v2.8c-.914-.149-2-.585-2-1.4zm5.334 4.083c0 .851-1.114 1.26-2 1.4v-2.8c.886.14 2 .549 2 1.4z"
      ></path>
    </svg>
  );
}

export default Amount;
