import React, { useState , useEffect } from 'react';
import Closebtn from '../../../Svg/Closebtn';
import "./Modal.css";
// import Lottie from 'react-lottie';
import Lottie from "lottie-react";
import { Bars } from 'react-loader-spinner'

function AddCounter({ closeModal }) {
    const [counterData, setCounterData] = useState({
    CounterNo: ''
  });


const [isLoading, setIsLoading] = useState(false);

  const handleChange = event => {
    const { name, value } = event.target;
    setCounterData({ ...counterData, [name]: value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    console.log(counterData);
    setIsLoading(true);
    // validate form data and submit to server or database
  };

  useEffect(() => {
    if (isLoading) {
      // simulate a long loading process
      setTimeout(() => {
        closeModal(false);
        setIsLoading(false);
      }, 3000);
    }
  }, [isLoading]);

return (

    <div className='Modalbg'>
            <div className="ModalContainer">
                                <div className='closebtn'>
                                <button
                                onClick={() =>
                                    closeModal(false)}
                                >
                                <Closebtn />
                                </button>
                                </div>
                                
                                <div className="ModalTitle">
                                    Add Counter
                                </div>
                            <form onSubmit={handleSubmit}>
                                
                            {isLoading ? (
                                    <div className="Modalbtn ct dr" >
                                        
                                            <Bars
                                                height="50"
                                                width="50"
                                                color="#FF8C00"
                                                ariaLabel="bars-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                            <p className='loadtlt'>Adding Counter</p>
                                    </div>
                                        ) : (
                               <div> 
                                    <div className="Modalbody">
                                        <div>
                                            <label for="Counter">Counter No.</label>
                                            <input type="number" min='1' max='100' name="CounterNo" value={counterData.CounterNo} onChange={handleChange} id="Counter" placeholder='Counter No.' required />
                                        </div>
                                    </div>
                                    <div className="Modalbtn ct">
                                        
                                            <button className='btn' type="submit">Add Counter</button>
                                    </div>
                                    
                                </div>
                                )}
                             </form>  
            </div>   
        
    </div>
  )
}

export default AddCounter;