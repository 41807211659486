import React from "react";

function AddSlide() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12 8.25a.75.75 0 00-.75.75v2.25H9a.75.75 0 100 1.5h2.25V15a.75.75 0 101.5 0v-2.25H15a.75.75 0 100-1.5h-2.25V9a.75.75 0 00-.75-.75z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        d="M8.59 3.77L9.97 2.354 8.9 1.307l-.933.956-1.335-.017a4.335 4.335 0 00-4.386 4.386l.015 1.335-.954.933a4.335 4.335 0 000 6.201l.955.933-.016 1.335a4.336 4.336 0 004.386 4.386l1.335-.015.933.954a4.334 4.334 0 006.2 0l.934-.955 1.335.016a4.334 4.334 0 004.386-4.386l-.015-1.335.954-.933a4.335 4.335 0 000-6.2l-.956-.934.017-1.335a4.336 4.336 0 00-4.386-4.386l-1.335.015-.933-.954a4.336 4.336 0 00-6.201 0L9.97 2.354a2.834 2.834 0 014.056 0l1.38 1.416 1.98-.024a2.834 2.834 0 012.867 2.868l-.024 1.977 1.416 1.382a2.834 2.834 0 010 4.056l-1.416 1.38.024 1.98a2.835 2.835 0 01-2.868 2.866l-1.977-.024-1.382 1.416a2.833 2.833 0 01-4.056 0l-1.38-1.416-1.98.024a2.833 2.833 0 01-2.866-2.868l.024-1.977-1.416-1.381a2.834 2.834 0 010-4.056l1.416-1.38-.024-1.98a2.835 2.835 0 012.868-2.867l1.977.024z"
      ></path>
    </svg>
  );
}

export default AddSlide;
