import React from "react";

function Voucher() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="14"
        fill="none"
        viewBox="0 0 16 14"
      >
        <path
          stroke="#A3A3A3"
          d="M4.868 12.944h.35l.12-.33a.67.67 0 01.252-.323.723.723 0 01.82 0 .67.67 0 01.252.324l.12.33H14.9V9.821l-.283-.136a3.066 3.066 0 01-1.256-1.11A2.928 2.928 0 0112.9 7c0-.556.16-1.102.462-1.576.303-.475.737-.86 1.256-1.11l.283-.136V1.056H6.782l-.12.33a.67.67 0 01-.252.323.724.724 0 01-.82 0 .67.67 0 01-.252-.324l-.12-.33H1.1v11.89h3.768zM.5.778C.5.708.528.64.583.586A.312.312 0 01.8.5h14.4c.084 0 .162.033.217.086a.267.267 0 01.083.192v3.827a2.518 2.518 0 00-1.263.662A2.418 2.418 0 0013.5 7c0 .653.267 1.276.737 1.733.35.34.79.568 1.263.662v3.827c0 .07-.028.138-.083.192a.312.312 0 01-.217.086H.8a.312.312 0 01-.217-.086.267.267 0 01-.083-.192V.778zM6 5.722a.718.718 0 01-.5-.2.65.65 0 01-.2-.466c0-.173.07-.34.2-.467s.31-.2.5-.2.37.073.5.2c.13.126.2.294.2.467a.65.65 0 01-.2.466c-.13.127-.31.2-.5.2zm0 3.89a.718.718 0 01-.5-.201.65.65 0 01-.2-.467c0-.172.07-.34.2-.466.13-.127.31-.2.5-.2s.37.073.5.2c.13.126.2.294.2.466a.65.65 0 01-.2.467c-.13.127-.31.2-.5.2z"
        ></path>
      </svg>
    );
  }

export default Voucher;
