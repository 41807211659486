import React from "react";
import "./Maindash.css";
import Orderno from "../../../Svg/Orderno";
import Ordercl from "../../../Svg/Ordercl";
import Ordertp from "../../../Svg/Ordertp";
import Items from "../../../Svg/Items";
import Amount from "../../../Svg/Amount";
// import { Scrollbars } from 'react-custom-scrollbars';

const data = [
  {
    OrderNo: "#23292423",
    OrdersDate: "12 Feb 23 at 16:07",
    OrdersType: "Take Away",
    Items: "4 Items",
    Amount: "255$",
    Status: "Ready",
  },
  {
    OrderNo: "#23292423",
    OrdersDate: "12 Feb 23 at 16:07",
    OrdersType: "Take Away",
    Items: "4 Items",
    Amount: "255$",
    Status: "Ready",
  },
  {
    OrderNo: "#23292423",
    OrdersDate: "12 Feb 23 at 16:07",
    OrdersType: "Take Away",
    Items: "4 Items",
    Amount: "255$",
    Status: "Ready",
  },
  {
    OrderNo: "#23292423",
    OrdersDate: "12 Feb 23 at 16:07",
    OrdersType: "Take Away",
    Items: "4 Items",
    Amount: "255$",
    Status: "Ready",
  },
  {
    OrderNo: "#23292423",
    OrdersDate: "12 Feb 23 at 16:07",
    OrdersType: "Take Away",
    Items: "4 Items",
    Amount: "255$",
    Status: "Ready",
  },
  {
    OrderNo: "#23292423",
    OrdersDate: "12 Feb 23 at 16:07",
    OrdersType: "Take Away",
    Items: "4 Items",
    Amount: "255$",
    Status: "Ready",
  },
];

function CurrentTable() {
  return (
    <div className="tab">
      <div className="App1">
        <div className="Table-top">
          <div className="Table-head">
            <Orderno />
            <h5>Orders No.</h5>
          </div>
          <div className="Table-head">
            <Ordercl />
            <h5>Orders Date</h5>
          </div>
          <div className="Table-head">
            <Ordertp />
            <h5>Orders Type</h5>
          </div>
          <div className="Table-head">
            <Items />
            <h5>Items</h5>
          </div>
          <div className="Table-head">
            <Amount />
            <h5>Amount</h5>
          </div>
          <div className="Table-head">
            <h5>Status</h5>
          </div>
        </div>
      </div>
      {data.map((val, key) => {
        return (
          <div className="App1">
            <div key={key} className="Table">
              <div className="Table-head">
                <h5>{val.OrderNo}</h5>
              </div>
              <div className="Table-head">
                <h5>{val.OrdersDate}</h5>
              </div>
              <div className="Table-head">
                <h5>{val.OrdersType}</h5>
              </div>
              <div className="Table-head">
                <h5>{val.Items}</h5>
              </div>
              <div className="Table-head">
                <h5>{val.Amount}</h5>
              </div>
              <div className="Table-head">
                <h5 className="status">{val.Status}</h5>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CurrentTable;
