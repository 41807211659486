import React from "react";
import "./Maindash.css";
import Orderno from "../../../Svg/Orderno";
// import Most from '../../../imgs';
// import Food1 from '../../../imgs/Food1.png'

const Most = [
  {
    image: "./Imagesfood/Food1.png",
    Itemname: "Zinger Burger",
    Number: "95",
  },
  {
    image: "./Imagesfood/Food2.png",
    Itemname: "American Zinger Burger",
    Number: "95",
  },
];

function MostTable() {
  return (
    <div className="tab1">
      <div className="App1">
        <div className="Table-top">
          <div className="Table-head">
            <Orderno />
          </div>
          <div className="Table-head1">
            <h5>Item Name</h5>
          </div>
          <div className="Table-head">
            <h5>No.</h5>
          </div>
        </div>
      </div>
      {Most.map((valu, key) => {
        return (
          <div className="App1">
            <div key={key} className="Table">
              <div className="Table-head">
                <img src={valu.image} alt="" />
              </div>
              <div className="Table-head1">
                <h5>{valu.Itemname}</h5>
              </div>
              <div className="Table-head">
                <h5>{valu.Number}</h5>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MostTable;
