import React from "react";

function Back() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#FFF4F4"></circle>
      <path
        fill="red"
        d="M6.938 11.438h11.25a.562.562 0 110 1.124H6.938a.563.563 0 010-1.124z"
      ></path>
      <path
        fill="red"
        d="M7.17 12l4.666 4.665a.563.563 0 01-.797.796L5.977 12.4a.563.563 0 010-.796l5.062-5.063a.563.563 0 11.797.796L7.17 12.001z"
      ></path>
    </svg>
  );
}

export default Back;