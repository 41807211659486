import React from "react";

function Tableoption() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="42"
      fill="none"
      viewBox="0 0 23 42"
    >
      <path
        fill="#F6951F"
        d="M13.681 14.905c0-.315-.066-.598-.198-.85a1.916 1.916 0 00-.53-.634c-.22-.17-.47-.3-.747-.388a2.789 2.789 0 00-1.72.01c-.278.094-.52.23-.729.406a2.041 2.041 0 00-.5.633c-.127.246-.19.52-.19.823 0 .303.066.577.199.823.132.245.305.457.52.633.214.177.46.312.737.407a2.621 2.621 0 001.721-.01c.277-.1.523-.24.738-.416.214-.176.384-.387.51-.633s.19-.514.19-.804z"
      ></path>
      <path
        fill="#F6951F"
        d="M22.748 25.463V0H0v25.463h10.164v13.423H6.342v2.42h10.064v-2.42h-3.822V25.463h10.164zm-11.393-6.265a5.667 5.667 0 01-1.89-.312 4.793 4.793 0 01-1.551-.88 4.137 4.137 0 01-1.04-1.342 3.825 3.825 0 01-.379-1.702c0-.378.063-.728.19-1.05.125-.32.29-.614.49-.879a4.167 4.167 0 011.42-1.191 3.172 3.172 0 01-1.192-1.021 2.496 2.496 0 01-.492-1.494c0-.605.136-1.138.407-1.598a3.81 3.81 0 011.059-1.163 4.957 4.957 0 011.437-.718 5.196 5.196 0 011.56-.246 5.52 5.52 0 011.56.236 4.625 4.625 0 011.447.71c.428.314.781.702 1.059 1.162.277.46.416.993.416 1.598 0 .265-.044.526-.132.785a2.76 2.76 0 01-.37.718 3.275 3.275 0 01-1.239 1.03 4.215 4.215 0 011.476 1.24c.202.27.362.57.483.898.12.328.18.687.18 1.078 0 .63-.13 1.198-.389 1.702a4.06 4.06 0 01-1.059 1.305 4.826 4.826 0 01-1.56.841 6.013 6.013 0 01-1.891.293z"
      ></path>
      <path
        fill="#F6951F"
        d="M10.098 10.584c.182.133.384.234.605.303.22.07.444.104.671.104.214 0 .435-.035.662-.104.227-.07.432-.173.615-.312.182-.139.33-.306.444-.501a1.35 1.35 0 00.17-.69c0-.467-.186-.839-.558-1.116-.372-.277-.822-.416-1.352-.416-.517 0-.958.142-1.324.425a1.354 1.354 0 00-.548 1.125c0 .265.057.495.17.69.114.196.262.36.445.492z"
      ></path>
      <path fill="#F6951F" d="M3.428 3.971H19.317V20.654H3.428z"></path>
    </svg>
  );
}

export default Tableoption;
