import React from "react";

function Sdelete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <circle cx="11" cy="11" r="11" fill="#fff"></circle>
      <path
        fill="red"
        d="M6.714 15.667c0 .733.643 1.333 1.429 1.333h5.714c.786 0 1.429-.6 1.429-1.333V9c0-.733-.643-1.333-1.429-1.333H8.143c-.786 0-1.429.6-1.429 1.333v6.667zm8.572-10H13.5l-.507-.474a.748.748 0 00-.5-.193H9.507a.748.748 0 00-.5.193l-.507.474H6.714c-.393 0-.714.3-.714.666C6 6.7 6.321 7 6.714 7h8.572c.393 0 .714-.3.714-.667 0-.366-.321-.666-.714-.666z"
      ></path>
    </svg>
  );
}

export default Sdelete;
