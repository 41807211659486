import React from 'react'
import '../Banners/Banner.css'
import './KioskOptions.css'
import Sets from '../../../Svg/Sets';
import Header from '../../Header/Header'
import Kioskselection from '../KioskItems/Kioskselection';
import { useState } from 'react';
import TAicon from '../../../Svg/TAicon';
import Toggle from '../UsersManagment/Toggle';
import DIicon from '../../../Svg/DIicon';
import PUicon from '../../../Svg/PUicon';
import Sidebar from '../../Sidebar/Sidebar';


function KioskOptions() {
  return (
    <div className="AppBack">
        <div className='Sider' > <Sidebar/></div>
    <div className='Container'>
        <Header title="Kiosk Options" />
        <div className="MainUsers">
            <div className='Top'>
                <div className="filters">
                    <Kioskselection />
                </div>
            </div>
            <div className="Maintab">
                <div className='MainTitle'>
                    <div className="sec">
                        <i> <Sets /> </i>
                        <h6 className='Menucardtitle'>Kiosk Options</h6>
                    </div>
                    <div className='Sliders'>
                        <div className="options">
                            <div className="optiontlt">
                                <TAicon />
                                <h1>Take Away Options</h1> 
                            </div>
                            <div className='toggling'>
                                    <Toggle />
                            </div>
                            
                        </div>
                        <div className="options2">
                            <div className="optiontlt">
                                <DIicon />
                                <h1>Dine-In Option</h1> 
                            </div>
                            <div className='toggling'>
                                    <Toggle />
                            </div>
                            
                        </div>
                        <div className="options3">
                            <div className="optiontlt">
                                <PUicon />
                                <h1>Pickup-later Option</h1> 
                            </div>
                            <div className='toggling'>
                                    <Toggle />
                            </div>
                            
                        </div>
    
                    </div>
                    <div className="btn9">
                        <button className='Savebutton'>
                                <h6 className='Countertext'>Save Update</h6>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default KioskOptions;