import React from "react";
import "./Kiosk.css";
import Header from "../../Header/Header";
import Kiosk from "../../../Svg/Kiosk";
import CloneKiosk from "./CloneKiosk";
import { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";

const kiosk = [
  {
    image: "./Imagesfood/kiosk.png",
    kioskname: "Kiosk 1",
    location: "Backdoor",
    kioskID: "0124847464647",
  },
  {
    image: "./Imagesfood/kiosk.png",
    kioskname: "Kiosk 2",
    location: "Backdoor",
    kioskID: "0124847464647",
  },
  {
    image: "./Imagesfood/kiosk.png",
    kioskname: "Kiosk 3",
    location: "Backdoor",
    kioskID: "0124847464647",
  },
  {
    image: "./Imagesfood/kiosk.png",
    kioskname: "Kiosk 4",
    location: "Backdoor",
    kioskID: "0124847464647",
  },
  {
    image: "./Imagesfood/kiosk.png",
    kioskname: "Kiosk 5",
    location: "Backdoor",
    kioskID: "0124847464647",
  },
];

const KioskManagment = () => {
  const koisks = [
    {
      value: 3,
      text: "Kiosk 3",
    },
    {
      value: 4,
      text: "Kiosk 4",
    },
  ];
  const [OpenModal3, setOpenModal3] = useState(false);

  return (
    <div className="AppBack">
      <div className="Sider">
        {" "}
        <Sidebar />
      </div>
      <div className="Container">
        <Header title="Kiosk Managment" options={koisks} />
        <div className="MainKiosk">
          <div className="ksection">
            {kiosk.map((valu, key) => {
              return (
                <div key={key} className="Kcard">
                  <div className="ktop">
                    <img src={valu.image} alt="" />
                    <h5 className="kname">{valu.kioskname}</h5>
                  </div>
                  <div className="kinfo">
                    <h5 className="kinfo1">Location </h5>
                    <h5 className="kinfo2">{valu.location}</h5>
                    <h5 className="kinfo1">Kiosk ID </h5>
                    <h5 className="kinfo2">{valu.kioskID}</h5>
                  </div>
                  <button
                    className="clonebutton"
                    onClick={() => {
                      setOpenModal3(true);
                    }}
                  >
                    {" "}
                    <i className="clone">
                      {" "}
                      <Kiosk />{" "}
                    </i>{" "}
                    Clone The Kiosk
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        {OpenModal3 && <CloneKiosk closeModal={setOpenModal3} />}
      </div>
    </div>
  );
};

export default KioskManagment;
