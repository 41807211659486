import React from 'react'
import './Header.css'
import Back from '../../Svg/Back'
import Table from '../../Svg/Table'
import { NavLink, Link,useParams } from 'react-router-dom'

const Orderheader = ({ title }) => {
  return (
    <div className='Main-header'>
        <div className="title">{title}</div>
        <NavLink className='Action' exact to="/TableManagement" style={{
        textDecoration: 'none',
        background: 'none',
        marginLeft: '0%',
        marginRight: '0%',
        }}>
        <button className='Main-Button'>
             <i className="ordericon"> <Table /> </i>
             <div className="title-button">Table Managment</div>
         </button>
        </NavLink>

        <NavLink className='Action' exact to="/" style={{
        textDecoration: 'none'
        }}>
         <button className='Main-Button'>
             <i className="ordericon"> <Back /> </i>
             <div className="title-button">Back To Admin Panel</div>
         </button>
         </NavLink>
    </div>
  )
}

export default Orderheader;