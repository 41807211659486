import '../KioskItems/Itemslist/Itemslist.css'
import EditIcon from '../../../Svg/EditIcon';
import DeleteIcon from '../../../Svg/DeleteIcon';
import Toggle from './Toggle';


function Usersdata  ({
        Name,
        Email,
        Phone,
        Role,
        Addeddate,

}) {

  return (
    <div className='Table'>
                    <div className='Table-head'>
                            <p className='title-u'>{Name}</p>
                    </div>
                    <div className='Table-head'>
                            <p className='title-u'>{Email}</p>
                    </div>
                    <div className='Table-head'>
                            <p className='title-u'>{Phone}</p>
                    </div>
                    <div className='Table-head'>
                            <p className='title-u'>{Role}</p>
                    </div>
                    <div className='Table-head'>
                            <p className='title-u'>{Addeddate}</p>
                    </div>
                    <div className='Table-head' id='Tags'>
                    <div
                        //   onClick={() => {
                        //     setOpen4(true);
                        //     setselecteduser(item);
                        //   }}
                        //   className="ico-edit pointer flex aic jc"
                        >
                          <EditIcon />
                        </div>
                        <div
                        //   onClick={() => {
                        //     setOpen3(true);
                        //     setusertobedelete(item);
                        //   }}
                        //   className="icon-del flex aic"
                        >
                          <DeleteIcon />
                        </div>
                        <div className='toggling'>
                        <Toggle />
                        </div>
                    </div>
                    
    </div>
    
  );
}

export default Usersdata;