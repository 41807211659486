import React from "react";

function Kitchen() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    fill="none"
    viewBox="0 0 12 16"
  >
    <path
      stroke="#A3A3A3"
      d="M10.5.508h0c.524 0 1 .454 1 1.092v12.8c0 .635-.481 1.1-1 1.1h-9c-.519 0-1-.465-1-1.1V1.6c0-.302.113-.585.304-.79A.954.954 0 011.5.5s0 0 0 0l9 .008zM2.25 14.9h7.5c.719 0 1.25-.615 1.25-1.3V7.984c0-.685-.531-1.3-1.25-1.3h-7.5c-.719 0-1.25.615-1.25 1.3V13.6c0 .685.531 1.3 1.25 1.3zm0-8.8h7.5c.719 0 1.25-.615 1.25-1.3V2.4c0-.685-.531-1.3-1.25-1.3h-7.5C1.531 1.1 1 1.715 1 2.4v2.4c0 .685.531 1.3 1.25 1.3zm1.5-3.2c.106 0 .25.105.25.3V4c0 .195-.144.3-.25.3-.106 0-.25-.105-.25-.3v-.8c0-.195.144-.3.25-.3zm0 5.6c.106 0 .25.105.25.3v2.4c0 .195-.144.3-.25.3-.106 0-.25-.105-.25-.3V8.8c0-.195.144-.3.25-.3z"
    ></path>
  </svg>
  );
}

export default Kitchen;