import React from "react";
import Search from "../../../../Svg/Search";
import Plus from "../../../../Svg/Plus";
import "./../Itemslist/Itemslist.css";
import Orderno from "../../../../Svg/Orderno";
import EditIcon from "../../../../Svg/EditIcon";
import DeleteIcon from "../../../../Svg/DeleteIcon";
import Toggle from "../../UsersManagment/Toggle";
import AddCategory from "./AddCategory";
import { useState } from "react";
import Categorysdata from "./Categorysdata";

function Category({ categories, kioskId }) {
  const [OpenModal5, setOpenModal5] = useState(false);

  return (
    <div className="itemslist">
      <div className="top-bar">
        <div className="custom-search">
          <input type="search" placeholder="Search Anything" />
          <i className="Iconic">
            <Search />
          </i>
        </div>
        <div>
          <button
            className="tablebutton"
            onClick={() => {
              setOpenModal5(true);
            }}
          >
            <Plus />
            <h6 className="tabletext"> Add New Category</h6>
          </button>
        </div>
      </div>
      <div className="tabi">
        <div className="Table-top">
          <div className="Table-head">
            <Orderno />
          </div>
          <div className="Table-head" style={{ justifyContent: "flex-start" }}>
            <h5>Name</h5>
          </div>
          <div className="Table-head">
            <h5>Number Items</h5>
          </div>
          <div className="Table-head">
            <h5>Status</h5>
          </div>
          <div className="Table-head">
            <h5>Action</h5>
          </div>
        </div>
        {categories.map((val, key) => {
          return (
            <div className="App1">
              <div key={key} className="Table">
                <Categorysdata
                  image={val.image}
                  CName={val.name}
                  Nitems={val?.items.length}
                  Status={val.Status}
                />
              </div>
            </div>
          );
        })}
      </div>
      {OpenModal5 && (
        <AddCategory kioskId={kioskId} closeModal={setOpenModal5} />
      )}
    </div>
  );
}

export default Category;
