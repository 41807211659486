import React from "react";
import Header from "../../Header/Header";
import Kioskselection from "../KioskItems/Kioskselection";
import Plus from "../../../Svg/Plus";
import AddVoucher from "./AddVoucher";
import { useState } from "react";
import Vouchersdata from "./Vouchersdata";
import Sidebar from "../../Sidebar/Sidebar";

const Voucherdata = [
  {
    Voucher: "Food Discount",
    Discount: "15%",
    Code: "Food15",
    Used: "15",
    ExpireDate: "15-02-2022",
    Action: "Ready",
  },
];

const Voucher = () => {
  const [OpenModal8, setOpenModal8] = useState(false);

  return (
    <div className="AppBack">
      <div className="Sider">
        {" "}
        <Sidebar />
      </div>
      <div className="Container">
        <Header title="Voucher Creation" />
        <div className="MainUsers">
          <div className="Top">
            <div className="filters">
              <Kioskselection />
            </div>
            <div className="buttonic">
              <button
                className="tablebutton"
                onClick={() => {
                  setOpenModal8(true);
                }}
              >
                <Plus />
                <h6 className="tabletext"> Add New Voucher </h6>
              </button>
            </div>
          </div>
          <div className="userlist">
            <div className="tab">
              <div className="Table-top">
                <div className="Table-head">
                  <h5>Voucher Name</h5>
                </div>
                <div className="Table-head">
                  <h5>Discount %</h5>
                </div>
                <div className="Table-head">
                  <h5>Voucher Code</h5>
                </div>
                <div className="Table-head">
                  <h5>Used</h5>
                </div>
                <div className="Table-head">
                  <h5>Expire Date</h5>
                </div>
                <div className="Table-head">
                  <h5>Action</h5>
                </div>
              </div>
              {Voucherdata.map((val, key) => {
                return (
                  <div className="App1">
                    <div className="Table">
                      <Vouchersdata
                        Voucher={val.Voucher}
                        Discount={val.Discount}
                        Code={val.Code}
                        Used={val.Used}
                        ExpireDate={val.ExpireDate}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {OpenModal8 && <AddVoucher closeModal={setOpenModal8} />}
      </div>
    </div>
  );
};

export default Voucher;
