import Search from "../../../../Svg/Search";
import Plus from "../../../../Svg/Plus";
import "./../Itemslist/Itemslist.css";
import Orderno from "../../../../Svg/Orderno";
import EditIcon from "../../../../Svg/EditIcon";
import DeleteIcon from "../../../../Svg/DeleteIcon";
import Toggle from "../../UsersManagment/Toggle";
import { useState } from "react";
import Ingredientsdata from "./Ingredientsdata";
import AddIngredient from "./AddIngredient";
import { useSelector } from "react-redux";

const Ingredientdata = [
  {
    gimg: "./Imagesfood/Food1.png",
    gname: "2x burger and 2 Drinks",
    Action: "Ready",
  },
];

function Ingredients({ kioskId }) {
  const { ingredients } = useSelector((state) => state.MerchantKitchenData);
  const [OpenModal9, setOpenModal9] = useState(false);
  return (
    <div className="itemslist">
      <div className="top-bar">
        <div className="custom-search">
          <input type="search" placeholder="Search Anything" />
          <i className="Iconic">
            <Search />
          </i>
        </div>
        <div>
          <button
            className="tablebutton"
            onClick={() => {
              setOpenModal9(true);
            }}
          >
            <Plus />
            <h6 className="tabletext"> Add New Ingredient</h6>
          </button>
        </div>
      </div>
      <div className="tabi">
        <div className="Table-top">
          <div className="Table-head">
            <Orderno />
          </div>
          <div className="Table-head" style={{ justifyContent: "flex-start" }}>
            <h5>Ingredient Name</h5>
          </div>
          ingredient1
          <div className="Table-head">
            <h5>Action</h5>
          </div>
        </div>
        {ingredients.map((val, key) => {
          return (
            <div className="App1">
              <div key={key} className="Table">
                <Ingredientsdata image={val.image} gname={val.name} />
              </div>
            </div>
          );
        })}
      </div>
      {OpenModal9 && <AddIngredient closeModal={setOpenModal9} />}
    </div>
  );
}

export default Ingredients;
