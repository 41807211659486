import React from 'react'
import Calender from '../../../Svg/Calender';
import './Datepicker.css'

function Datepicker() {
  return (
    <div>
          <button className='Calender'>
             {/* <i className="Date"> <Calender /> </i> */}
             <input type="date" className="title-picker" />
         </button>
    </div>
  )
}

export default Datepicker;