import React from "react";

function Camera() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="43"
      fill="none"
      viewBox="0 0 47 43"
    >
      <path
        fill="#F6951F"
        d="M6.13 6.13V0h4.087v6.13h6.13v4.087h-6.13v6.13H6.13v-6.13H0V6.13h6.13zm6.13 12.261v-6.13h6.131V6.13h14.305l3.678 4.086h6.539A4.099 4.099 0 0147 14.304v24.522a4.099 4.099 0 01-4.087 4.087H10.217a4.099 4.099 0 01-4.087-4.087V18.391h6.13zm14.305 18.392c9.094 0 13.671-10.994 7.234-17.452-6.437-6.437-17.451-1.86-17.451 7.234 0 5.64 4.577 10.218 10.217 10.218zm-6.539-10.218c0 5.824 7.05 8.746 11.157 4.618 4.128-4.107 1.206-11.157-4.618-11.157a6.56 6.56 0 00-6.539 6.54z"
      ></path>
    </svg>
  );
}

export default Camera;
