import React from "react";

function Banner() {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          fill="none"
          viewBox="0 0 17 17"
        >
          <path
            stroke="#A3A3A3"
            d="M12.526 15.907h.5v-6.46h.79v5.763c0 .708-.582 1.29-1.29 1.29H1.79C1.081 16.5.5 15.917.5 15.21V4.473c0-.708.581-1.29 1.29-1.29h5.763v.79H1.289v11.934h11.237z"
          ></path>
          <path
            stroke="#A3A3A3"
            d="M12.525 3.184h.5V.5h.79v2.684h2.684v.79h-2.684V6.65a322.81 322.81 0 01-.79.002v-2.68h-2.682a213.216 213.216 0 00.002-.789h2.18zm-8.447 3.58h6.158v.789H4.078v-.79zm6.158 3.473H4.078v-.79h6.158v.79zm-6.158 1.895h6.158v.79H4.078v-.79z"
          ></path>
        </svg>
      );
    }

export default Banner;
