import React from "react";
import { useState, useEffect } from "react";
import Closebtn from "../../../../Svg/Closebtn";
import Camera from "../../../../Svg/Camera";
import Sdelete from "../../../../Svg/Sdelete";
import Select from "react-select";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getKioskData } from "../../../../redux/Actions/action";

const Users = [
  {
    value: "01",
    label: "Kitchen",
  },
  {
    value: "02",
    label: "Admin",
  },
  {
    value: "03",
    label: "Cafe",
  },
];

function AddDeals({ closeModal, kioskId, itemList }) {
  console.log("iiiiiii", itemList);
  const [deal, setDeal] = useState({
    image: "",
    name: "",
    price: "",
    items: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeal({ ...deal, [name]: value });
  };

  const handleSelectChange = (selected, name) => {
    var values = selected.map(function (obj) {
      let value = obj["id"];
      return { id: value };
    });
    console.log(values);
    setDeal({ ...deal, [name]: values });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    const image = new Image();
    image.src = url;
    image.onload = () => {
      const { naturalWidth, naturalHeight } = image;
      if (naturalWidth !== 400 || naturalHeight !== 400) {
        setError("Low Resolution or Wrong Size (MUST BE 400X400)");
      } else {
        setDeal({ ...deal, image: file });
      }
    };
  };

  const handleImageDelete = () => {
    setDeal({ ...deal, image: "" });
    setError("");
  };
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("dealss", JSON.stringify(deal));
    // const { dimg, dname, dprice, ditems } = deal;
    // console.log(image,kiosk,name)
    const formData = new FormData();
    formData.append("name", deal.name);
    formData.append("image", deal.image);
    formData.append("price", deal.price);
    formData.append("items", JSON.stringify(deal.items));
    formData.append("kiosk", kioskId);
    console.log(formData);
    // formData.append("price", deal.price);
    // formData.append("item", deal.items);
    try {
      const newDeal = await axios.post(
        "http://159.69.211.133:3000/deals",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (newDeal.status === 201) {
        dispatch(getKioskData(kioskId));
      }
      // console.log("newCat", newCategory);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(true);
    // do something with the form values
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      // simulate a long loading process
      setTimeout(() => {
        setIsLoading(false);
        closeModal(false);
      }, 5000);
    }
  }, [isLoading]);

  return (
    <div className="Modalbg">
      <div className="ModalContainer" style={{ width: "550px" }}>
        <div className="closebtn">
          <button onClick={() => closeModal(false)}>
            <Closebtn />
          </button>
        </div>
        <div className="ModalTitle">Create New Deal</div>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <div className="Modalbtn ct dr">
              <Bars
                height="50"
                width="50"
                color="#FF8C00"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <p className="loadtlt">Adding Item</p>
            </div>
          ) : (
            <div>
              <div className="Modalbody">
                {/* First Section Images And Deal name and Item Type */}
                <div className="dblsection">
                  {deal.image ? (
                    <div className="Imgs center">
                      <img
                        className="iimg"
                        src={URL.createObjectURL(deal.image)}
                        alt="Selected"
                      />
                      <button
                        type="delete"
                        className="imgdel"
                        onClick={handleImageDelete}
                      >
                        <Sdelete />
                      </button>
                    </div>
                  ) : (
                    <div className="Imgs">
                      <label htmlFor="imgs">
                        <div className="addmore">
                          <i>
                            <Camera />
                          </i>
                          <p className="mains tlt">Choose Deal Image</p>
                          {error ? (
                            <p className="error">{error}</p>
                          ) : (
                            <p className="mains line">MUST BE PNG (400X400)</p>
                          )}
                        </div>
                      </label>
                      <input
                        className="inp"
                        id="imgs"
                        name="image"
                        value={deal.image}
                        onChange={handleImageChange}
                        type="file"
                        accept="image/png"
                        required
                      />
                    </div>
                  )}
                  <div className="snglsection">
                    <div className="fields">
                      <label for="name">Deal Name</label>
                      <input
                        name="name"
                        value={deal.name}
                        onChange={handleChange}
                        type="text"
                        id="name"
                        placeholder="Deal Name"
                        required
                      />
                    </div>
                    <div className="fields">
                      <label for="Price">Deal Price</label>
                      <input
                        name="price"
                        value={deal.price}
                        onChange={handleChange}
                        type="number"
                        id="Price"
                        placeholder="Deal Price"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="dblsection">
                <div className="fields btm">
                  <h1 className="userTitle">Deal Items</h1>
                  <Select
                    isMulti
                    value={deal.items}
                    onChange={(selected) =>
                      handleSelectChange(selected, "items")
                    }
                    isClearable={false}
                    maxMenuHeight={82}
                    autosize={true}
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {e.icon}
                        <span style={{ marginLeft: 10 }}>{e.name}</span>
                      </div>
                    )}
                    options={itemList}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Deal Item"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#FFD39E",
                        primary: "#FF8C00",
                        primary50: "#FFD39E",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="Modalbtn">
                <button
                  id="Cancel"
                  className="btn"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn">
                  Create Deal
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddDeals;
