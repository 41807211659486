import Search from "../../../../Svg/Search";
import Plus from "../../../../Svg/Plus";
import "./../Itemslist/Itemslist.css";
import Orderno from "../../../../Svg/Orderno";
import EditIcon from "../../../../Svg/EditIcon";
import DeleteIcon from "../../../../Svg/DeleteIcon";
import Toggle from "../../UsersManagment/Toggle";
import { useEffect, useState } from "react";
import Extrasdata from "./Extrasdata";
import AddExtra from "./AddExtra";
import { getMerchantKitchenData } from "../../../../redux/Actions/action";
import { useDispatch, useSelector } from "react-redux";

const Extradata = [
  {
    eimg: "./Imagesfood/Food1.png",
    ename: "2x burger and 2 Drinks",
    eprice: "48KR",
    Action: "Ready",
  },
];

function Extras({ kioskId }) {
  const [OpenModal8, setOpenModal8] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getMerchantKitchenData("60b1f6cf-f894-4ef2-b72f-9c19811e0e4d"));
  // }, []);
  const { extras } = useSelector((state) => state.MerchantKitchenData);
  console.log("extras", extras);
  return (
    <div className="itemslist">
      <div className="top-bar">
        <div className="custom-search">
          <input type="search" placeholder="Search Anything" />
          <i className="Iconic">
            <Search />
          </i>
        </div>
        <div>
          <button
            className="tablebutton"
            onClick={() => {
              setOpenModal8(true);
            }}
          >
            <Plus />
            <h6 className="tabletext"> Add New Extra</h6>
          </button>
        </div>
      </div>
      <div className="tabi">
        <div className="Table-top">
          <div className="Table-head">
            <Orderno />
          </div>
          <div className="Table-head" style={{ justifyContent: "flex-start" }}>
            <h5>Extra Name</h5>
          </div>
          <div className="Table-head">
            <h5>Price</h5>
          </div>
          <div className="Table-head">
            <h5>Action</h5>
          </div>
        </div>
        {extras.map((val, key) => {
          return (
            <div className="App1">
              <div key={key} className="Table">
                <Extrasdata
                  image={val.image}
                  ename={val.name}
                  eprice={val.price}
                />
              </div>
            </div>
          );
        })}
      </div>
      {OpenModal8 && <AddExtra closeModal={setOpenModal8} />}
    </div>
  );
}

export default Extras;
