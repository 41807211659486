import React from "react";
import "./Itemslist.css";
import EditIcon from "../../../../Svg/EditIcon";
import DeleteIcon from "../../../../Svg/DeleteIcon";
import Toggle from "../../UsersManagment/Toggle";

const data4 = [
  {
    image: "./Imagesfood/Food1.png",
    Name: "Special Zinger burger",
    Category: "Burgers",
    Price: "200KR",
    Type: "Fast Food",
    Attributes: "Most Selling",
    Action: "Ready",
  },
];

function Itemsdata({ image, Name, Category, Price, Type, Attributes }) {
  console.log("image=>", image);
  return (
    <div className="Table">
      <div className="Table-head">
        <img src={`http://159.69.211.133:3000/${image}`} alt="" />
      </div>
      <div className="Table-head" style={{ justifyContent: "flex-start" }}>
        <p className="title-u">{Name}</p>
      </div>
      <div className="Table-head">
        <p className="title-u">{Category}</p>
      </div>
      <div className="Table-head">
        <p className="title-u">{Price}</p>
      </div>
      <div className="Table-head">
        <p className="title-u">{Type}</p>
      </div>
      <div className="Table-head">
        <p className="title-u">{Attributes ? "Most Selling" : "Normal"}</p>
      </div>
      <div className="Table-head" id="Tags">
        <div
        //   onClick={() => {
        //     setOpen4(true);
        //     setselecteduser(item);
        //   }}
        //   className="ico-edit pointer flex aic jc"
        >
          <EditIcon />
        </div>
        <div
        //   onClick={() => {
        //     setOpen3(true);
        //     setusertobedelete(item);
        //   }}
        //   className="icon-del flex aic"
        >
          <DeleteIcon />
        </div>
        <div className="toggling">
          <Toggle />
        </div>
      </div>
    </div>
  );
}

export default Itemsdata;
