import React from "react";

function Kiosk() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#A3A3A3"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.71 15.167h7.457V1H4.355v3.728"
      >
      </path>
      <path
        stroke="#A3A3A3"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 4.729h6.71v10.438H1V4.73z"
      >
      </path>
      <path
        stroke="#A3A3A3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.574 12.93h.746m-6.338 0h.746-.746z"
      >

      </path>
    </svg>
  );
}

export default Kiosk;