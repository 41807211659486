import React from 'react'
import DeleteIcon from '../../../Svg/DeleteIcon';
import EditIcon from '../../../Svg/EditIcon';
import Toggle from '../UsersManagment/Toggle';


function Vouchersdata  ({
        Voucher,
        Discount,
        Code,
        Used,
        ExpireDate,

}) {

  return (
    <div className='Table'>
                    <div className='Table-head'>
                            <p className='title-u'>{Voucher}</p>
                    </div>
                    <div className='Table-head'>
                            <p className='title-u'>{Discount}</p>
                    </div>
                    <div className='Table-head'>
                            <p className='title-u'>{Code}</p>
                    </div>
                    <div className='Table-head'>
                            <p className='title-u'>{Used}</p>
                    </div>
                    <div className='Table-head'>
                            <p className='title-u'>{ExpireDate}</p>
                    </div>
                    <div className='Table-head' id='Tags'>
                    <div
                        //   onClick={() => {
                        //     setOpen4(true);
                        //     setselecteduser(item);
                        //   }}
                        //   className="ico-edit pointer flex aic jc"
                        >
                          <EditIcon />
                        </div>
                        <div
                        //   onClick={() => {
                        //     setOpen3(true);
                        //     setusertobedelete(item);
                        //   }}
                        //   className="icon-del flex aic"
                        >
                          <DeleteIcon />
                        </div>
                        <div className='toggling'>
                        <Toggle />
                        </div>
                    </div>
                    
    </div>
    
  );
}

export default Vouchersdata;