import React from "react";

function Takeaway() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#FF8C00" rx="15"></rect>
      <path
        fill="#fff"
        d="M14.355 18.093v-2.92a.244.244 0 00-.244-.243c-1.492 0-1.701 2.56-1.71 2.668a.244.244 0 00.135.237l.354.177v.08l-.19 1.894a.733.733 0 00.73.806h.385a.733.733 0 00.73-.806l-.19-1.893zm-1.449-.62c.043-.342.118-.68.225-1.007.188-.553.434-.889.736-1.003v2.398h-.489a.244.244 0 00-.135-.219l-.337-.168zm1.09 2.75a.239.239 0 01-.18.08h-.387a.244.244 0 01-.243-.268l.169-1.686h.535l.168 1.686a.24.24 0 01-.062.188z"
      ></path>
      <path
        fill="#fff"
        d="M22.171 7.357h-1.954a.244.244 0 00-.244.245v.244h-5.1c-.22 0-.438.055-.631.161L11.85 9.311H8.982c-.411 0-.733.43-.733.977 0 .512.28.92.652.972l-1.102 3.728a1.225 1.225 0 00-.04.306v6.963a.244.244 0 00.245.244h10.992a.244.244 0 00.244-.244v-6.943c0-.117-.017-.233-.05-.345l-1.017-3.46a1.322 1.322 0 001.308-1.22h.492v.488a.244.244 0 00.244.244h1.954a.244.244 0 00.244-.244V7.602a.244.244 0 00-.244-.245zM8.737 10.288c0-.298.145-.488.245-.488h6.982l-.977.977H8.982c-.1 0-.245-.19-.245-.489zm7.608 4.71a1.226 1.226 0 00-.036.296v6.718h-8.06v-6.718c0-.059.007-.117.02-.173l1.14-3.856h5.136a1.076 1.076 0 001.584 1.4l1.175-.914-.956 3.237a.292.292 0 00-.003.01zm1.43 6.383l.631.631h-1.263l.631-.631zm.947-6.274c.02.068.03.137.03.207v6.353l-.733-.733v-5.272a.244.244 0 00-.489 0v5.272l-.733.733v-6.373c0-.059.008-.117.021-.173l.956-3.236.948 3.223zm1.25-5.307h-.586a.39.39 0 00-.39.39.832.832 0 01-.831.831h-.6a.344.344 0 00-.211.072l-1.525 1.186a.587.587 0 01-.775-.878l1.672-1.673a.245.245 0 00-.037-.375.244.244 0 00-.136-.042H12.87l1.606-.875a.833.833 0 01.397-.102h5.1V9.8zm1.955.733H20.46V7.846h1.466v2.687z"
      ></path>
      <path
        fill="#fff"
        d="M12.157 15.174a.244.244 0 00-.489 0v.733h-.488v-.733a.244.244 0 00-.489 0v.733h-.488v-.733a.244.244 0 00-.489 0v.977c0 .038.01.075.026.11l.459.916-.203 2.83a.733.733 0 00.731.785h.417a.732.732 0 00.73-.785l-.202-2.83.459-.917a.244.244 0 00.025-.11v-.976zm-.64 1.221l-.244.489h-.675l-.244-.489h1.163zm-.195 3.83a.243.243 0 01-.178.078h-.417a.244.244 0 01-.243-.261l.19-2.67h.523l.19 2.67a.242.242 0 01-.065.183z"
      ></path>
    </svg>
  );
}

export default Takeaway;