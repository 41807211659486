export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
export const ITEM_LIST_PENDING = "ITEM_LIST_PENDING";
export const ITEM_LIST_SUCCESS = "ITEM_LIST_SUCCESS";
export const ITEM_LIST_FAIL = "ITEM_LIST_FAIL";
export const GET_USER_PENDING = "GET_USER_PENDING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const GET_BRANCHES_PENDING = "GET_BRANCHES_PENDING";
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS";
export const GET_BRANCHES_FAIL = "GET_BRANCHES_FAIL";
export const GET_KIOSK_DATA_PENDING = "GET_KIOSK_DATA_PENDING";
export const GET_KIOSK_DATA_SUCCESS = "GET_KIOSK_DATA_SUCCESS";
export const GET_KIOSK_DATA_FAIL = "GET_KIOSK_DATA_FAIL";
export const GET_MERCHANT_KITCHEN_DATA_PENDING =
  "GET_MERCHANT_KITCHEN_DATA_PENDING";
export const GET_MERCHANT_KITCHEN_DATA_SUCCESS =
  "GET_MERCHANT_KITCHEN_DATA_SUCCESS";
export const GET_MERCHANT_KITCHEN_DATA_FAIL = "GET_MERCHANT_KITCHEN_DATA_FAIL";
