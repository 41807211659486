import React, { useState, useEffect } from "react";
import Closebtn from "../../../../Svg/Closebtn";
import "../../Cafe/Modal.css";
import Select from "react-select";
import Camera from "../../../../Svg/Camera";
import Switch from "react-input-switch";
import Sdelete from "../../../../Svg/Sdelete";
import { Bars } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  getKioskData,
  getMerchantKitchenData,
} from "../../../../redux/Actions/action";
import axios from "axios";

const category = [
  {
    label: "Kitchen",
    value: "Kitchen",
  },
  {
    label: "Admin",
    value: "Admin",
  },
  {
    label: "Cafe",
    value: "Cafe",
  },
];

const styless = {
  track: {
    backgroundColor: "#A3A3A3",
  },
  trackChecked: {
    backgroundColor: "#00D292",
  },

  button: {
    backgroundColor: "white",
  },
  buttonChecked: {
    backgroundColor: "white",
  },
};

function Additems({ closeModal, categories, sides, kioskId }) {
  // ITEM Submit Function
  const [item, setItem] = useState({
    image: "",
    name: "",
    price: "",
    category: [],
    mostSelling: false,
    status: false,
    size: "large",
    //medium: false,
    //m: "",most
    //large: false,
    //lprice: "",
    //exlarge: false,
    //exlprice: "",
    sides: [],
    extralist: [],
    ingredientlist: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };

  const handleSelectChange = (selected, name) => {
    if (Array.isArray(selected)) {
      const value = selected.map((obj) => {
        let value = obj["id"];
        return { id: value };
      });
      setItem({ ...item, [name]: value });
    } else {
      setItem({ ...item, [name]: selected?.id });
    }
  };
  const handleSelectChangeSide = (selected, name) => {
    setItem({ ...item, [name]: [{ id: selected?.id }] });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    const image = new Image();
    image.src = url;
    image.onload = () => {
      const { naturalWidth, naturalHeight } = image;
      if (naturalWidth !== 400 || naturalHeight !== 400) {
        // setItem({
        //   ...item,
        //   error: "Low Resolution or Wrong Size (MUST BE 400X400) ",
        // });
        alert("image error");
      } else {
        setItem({ ...item, image: file });
      }
    };
  };

  const handleImageDelete = () => {
    setItem({ ...item, image: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(item);
    const formData = new FormData();
    formData.append("name", item.name);
    formData.append("image", item.image);
    formData.append("price", item.price);
    formData.append("category", item.category);
    formData.append("mostSelling", item.mostSelling);
    formData.append("size", item.size);
    formData.append("status", item.status);
    formData.append("side", JSON.stringify(item.sides));
    formData.append("extras", JSON.stringify(item.extralist));
    formData.append("ingredients", JSON.stringify(item.ingredientlist));
    console.log(formData);
    // formData.append("price", deal.price);
    // formData.append("item", deal.items);
    try {
      const newDeal = await axios.post(
        "http://159.69.211.133:3000/item",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // console.log(new)
      console.log("newCat", newDeal);
      if (newDeal.status === 201) {
        alert("success");
        dispatch(getKioskData(kioskId));
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(true);
    // do  with the form values
  };

  // Loading Funtion

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      // simulate a long loading process
      setTimeout(() => {
        setIsLoading(false);
        closeModal(false);
      }, 5000);
    }
  }, [isLoading]);

  //SIZE Switches ON / OFF

  const Switch1 = (newValue) => {
    // setItem({ ...item, medium: newValue });
  };
  const Switch2 = (newValue) => {
    // setItem({ ...item, large: newValue });
  };
  const Switch3 = (newValue) => {
    // setItem({ ...item, exlarge: newValue });
  };

  // Size Function START

  const [show, setShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const [show2, setShow2] = useState(false);

  // Size Function END
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getMerchantKitchenData("60b1f6cf-f894-4ef2-b72f-9c19811e0e4d"));
  // }, []);
  const { extras, ingredients } = useSelector(
    (state) => state.MerchantKitchenData
  );
  return (
    <div className="Modalbg">
      <div className="ModalContainer" style={{ width: "550px" }}>
        <div className="closebtn">
          <button onClick={() => closeModal(false)}>
            <Closebtn />
          </button>
        </div>
        <div className="ModalTitle">Create New Item</div>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <div className="Modalbtn ct dr">
              <Bars
                height="50"
                width="50"
                color="#FF8C00"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <p className="loadtlt">Adding Item</p>
            </div>
          ) : (
            <div>
              <div className="Modalbody">
                {/* First Section Images And Item name and Price */}
                <div className="dblsection">
                  {item.image ? (
                    <div className="Imgs center">
                      <img
                        className="iimg"
                        src={URL.createObjectURL(item.image)}
                        alt="Selected"
                      />
                      <button
                        type="delete"
                        className="imgdel"
                        onClick={handleImageDelete}
                      >
                        <Sdelete />
                      </button>
                    </div>
                  ) : (
                    <div className="Imgs">
                      <label htmlFor="imgs">
                        <div className="addmore">
                          <i>
                            <Camera />
                          </i>
                          <p className="mains tlt">Choose Item Image</p>
                          {item.error ? (
                            <p className="error">{item.error}</p>
                          ) : (
                            <p className="mains line">MUST BE PNG (400X400)</p>
                          )}
                        </div>
                      </label>
                      <input
                        className="inp"
                        id="imgs"
                        name="image"
                        value={item.image}
                        onChange={handleImageChange}
                        type="file"
                        accept="image/png"
                      />
                    </div>
                  )}

                  <div className="snglsection">
                    <div className="fields">
                      <label for="Item">Item Name</label>
                      <input
                        type="text"
                        name="name"
                        value={item.name}
                        onChange={handleChange}
                        id="Item"
                        placeholder="Item Name"
                      />
                    </div>
                    <div className="fields">
                      <label for="Price">Price</label>
                      <input
                        name="price"
                        value={item.price}
                        onChange={handleChange}
                        type="number"
                        id="Price"
                        placeholder="Price"
                      />
                    </div>
                  </div>
                </div>

                {/* Category and Item Type Section */}
                <div className="dblsection">
                  <div className="fields">
                    <h1 className="userTitle">Category</h1>
                    <Select
                      value={item.category}
                      onChange={(selected) =>
                        handleSelectChange(selected, "category")
                      }
                      autosize={true}
                      options={categories}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder="Category"
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {e.icon}
                          <span style={{ marginLeft: 10 }}>{e.name}</span>
                        </div>
                      )}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#FFD39E",
                          primary: "#FF8C00",
                          primary50: "#FFD39E",
                        },
                      })}
                    />
                  </div>
                </div>

                {/* Attributes and Sizes Section */}

                <div className="dblsection">
                  {/* Attributes Section */}

                  <div className="snglsection">
                    <p className="ttl">Attributes</p>

                    <div className="Most">
                      <p className="ttl">Most Selling</p>
                      <div className="toggling">
                        <label htmlFor="most"></label>
                        <Switch
                          styles={styless}
                          value={item.mostSelling ? 1 : 0}
                          onChange={(newValue) => {
                            setItem({
                              ...item,
                              mostSelling: newValue ? true : false,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Attributes Section END */}

                  {/* Size Section */}

                  <div className="snglsection">
                    <p className="ttl">Other Sizes</p>

                    <div className="Most">
                      <div className="Attl">
                        <p className="ttl">Medium Size</p>
                      </div>
                      <div className="ptlt">
                        <div className="toggling">
                          <Switch
                            name="medium"
                            styles={styless}
                            value={item.medium}
                            onChange={(e) => {
                              setShow(e);
                              Switch1(e);
                            }}
                          />
                        </div>

                        {show ? (
                          <div className="fields price">
                            <input
                              name="mprice"
                              value={item.mprice}
                              onChange={handleChange}
                              type="number"
                              min="0"
                              placeholder="$"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="Most">
                      <div className="Attl">
                        <p className="ttl">Large Size</p>
                      </div>
                      <div className="ptlt">
                        <div className="toggling">
                          <Switch
                            styles={styless}
                            value={item.large}
                            onChange={(e) => {
                              setShow1(e);
                              Switch2(e);
                            }}
                          />
                        </div>

                        {show1 ? (
                          <div className="fields price">
                            <input
                              name="lprice"
                              value={item.lprice}
                              onChange={handleChange}
                              type="number"
                              min="0"
                              placeholder="$"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="Most">
                      <div className="Attl">
                        <p className="ttl">Extra Large Size</p>
                      </div>
                      <div className="ptlt">
                        <div className="toggling">
                          <Switch
                            styles={styless}
                            value={item.exlarge}
                            onChange={(e) => {
                              setShow2(e);
                              Switch3(e);
                            }}
                          />
                        </div>

                        {show2 ? (
                          <div className="fields price">
                            <input
                              name="exlprice"
                              value={item.exlprice}
                              onChange={handleChange}
                              type="number"
                              min="0"
                              placeholder="$"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Size Section END */}

                {/* Select Sides Section START */}

                <div className="dblsection">
                  <div className="fields">
                    <h1 className="userTitle">Select Sides</h1>
                    <Select
                      value={item.sides}
                      onChange={(selected) =>
                        handleSelectChangeSide(selected, "sides")
                      }
                      options={sides}
                      autosize={true}
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {e.icon}
                          <span style={{ marginLeft: 10 }}>{e.type}</span>
                        </div>
                      )}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder="Sides"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#FFD39E",
                          primary: "#FF8C00",
                          primary50: "#FFD39E",
                        },
                      })}
                    />
                  </div>
                </div>

                {/* Select Sides Section END */}

                {/* Add Extra & Ingredients Section START */}

                <div className="dblsection f">
                  {/* ? Add Extras In Items START */}

                  <div className="snglsection fields">
                    <p className="ttl">Extras</p>
                    <Select
                      isMulti
                      value={item.extralist}
                      onChange={(selected) =>
                        handleSelectChange(selected, "extralist")
                      }
                      options={extras}
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {e.icon}
                          <span style={{ marginLeft: 10 }}>{e.name}</span>
                        </div>
                      )}
                      isClearable={false}
                      autosize={true}
                      // options={Users}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder="Extras"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#FFD39E",
                          primary: "#FF8C00",
                          primary50: "#FFD39E",
                        },
                      })}
                    />
                  </div>
                  {/* Add Extras In Items END

                        {/* Add Ingredients In Items START */}

                  <div className="snglsection fields">
                    <p className="ttl">Ingredients</p>
                    <Select
                      isMulti
                      value={item.ingredientlist}
                      onChange={(selected) =>
                        handleSelectChange(selected, "ingredientlist")
                      }
                      options={ingredients}
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {e.icon}
                          <span style={{ marginLeft: 10 }}>{e.name}</span>
                        </div>
                      )}
                      isClearable={false}
                      autosize={true}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder="Ingredients"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#FFD39E",
                          primary: "#FF8C00",
                          primary50: "#FFD39E",
                        },
                      })}
                    />
                  </div>

                  {/* Add Ingredients In Items END */}
                </div>

                {/* Add Extra & Ingredients Section END */}
              </div>
              <div className="Modalbtn">
                <button
                  id="Cancel"
                  className="btn"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn">
                  Create Item
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Additems;
