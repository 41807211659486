import React from "react";

function DIicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
      viewBox="0 0 41 41"
    >
      <circle
        cx="20.5"
        cy="20.5"
        r="19.75"
        stroke="#F6951F"
        strokeWidth="1.5"
      ></circle>
      <path
        fill="#FFEBD2"
        d="M23.272 18.423c-.01-.676-.024-2.056 0-2.175.566-1.37.328-.804 1.906-2.353 1.263-1.24 2.93-1.37 3.605-1.28.546-.02 1.435.577.625 3.127s-2.184 3.386-2.77 3.485l-1.46.238h-.804l-.327.328-8.698 10.693c-.298.11-1.013.262-1.49 0-.476-.262-.516-1.161-.476-1.578l7.297-7.477 2.324-2.204.268-.804z"
      ></path>
      <path
        fill="#F6951F"
        d="M25.107 19.671l1.115-.07a2.626 2.626 0 001.963-1.143l.882-1.294a5.219 5.219 0 00.837-3.829 1.182 1.182 0 00-1.105-.98 5.217 5.217 0 00-3.701 1.29l-1.178 1.03a2.626 2.626 0 00-.9 2.08v.007l.064 1.115a1.57 1.57 0 01-.437 1.176l-9.205 9.457c-.247.257-.374.61-.359.993.017.422.211.837.518 1.109a1.59 1.59 0 001.163.381c.383-.03.717-.199.944-.476l8.283-10.27a1.57 1.57 0 011.116-.576zm-1.558.217l-8.282 10.27a.774.774 0 01-.548.268 1.012 1.012 0 01-.74-.24 1.012 1.012 0 01-.327-.706.773.773 0 01.199-.574l9.205-9.457a2.14 2.14 0 00.596-1.604l-.064-1.113a2.06 2.06 0 01.706-1.628l1.178-1.03a4.655 4.655 0 013.3-1.15.61.61 0 01.57.506 4.652 4.652 0 01-.745 3.414l-.882 1.293a2.06 2.06 0 01-1.532.896l-1.112.07a2.141 2.141 0 00-1.522.785z"
      ></path>
      <path
        fill="#F6951F"
        d="M29.453 28.438l-6.172-5.622a.285.285 0 00-.383.421l6.17 5.62c.147.136.23.336.235.564.005.27-.1.54-.284.724a1.01 1.01 0 01-.724.284.774.774 0 01-.562-.233l-5.5-6.039a.285.285 0 00-.42.383l5.5 6.04a1.338 1.338 0 001 .418 1.59 1.59 0 001.108-.45c.29-.291.46-.716.45-1.139a1.34 1.34 0 00-.418-.97zM14.433 19.618a2.634 2.634 0 001.728.696l1.117.004a1.57 1.57 0 011.148.506l.493.54a.284.284 0 00.401.02.285.285 0 00.02-.403l-.494-.54a2.14 2.14 0 00-1.566-.692l-1.114-.004a2.067 2.067 0 01-1.335-.534l-3.238-3.849a.098.098 0 01.075-.161c.016 0 .045.003.07.028l2.947 2.948a.748.748 0 001.057 0l.346-.347.615-.615.347-.346a.748.748 0 000-1.057l-2.948-2.947a.098.098 0 01.133-.145l3.85 3.238c.331.367.52.84.533 1.335l.004 1.114c.002.594.254 1.165.692 1.567l.527.48a.285.285 0 00.383-.42l-.526-.48a1.57 1.57 0 01-.507-1.148l-.004-1.118v-.006a2.634 2.634 0 00-.722-1.747l-3.864-3.25a.668.668 0 00-.901.982l2.947 2.948c.07.069.07.182 0 .252l-.145.145-3.284-3.285a.284.284 0 10-.403.402l3.285 3.285-.213.213-3.285-3.285a.284.284 0 10-.402.402l3.285 3.285-.145.145a.178.178 0 01-.252 0l-2.948-2.947a.663.663 0 00-.943 0 .664.664 0 00-.04.902l3.25 3.863.026.026z"
      ></path>
    </svg>
  );
}

export default DIicon;
