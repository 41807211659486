import React from "react";

function Sets() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="16"
      fill="none"
      viewBox="0 0 19 16"
    >
      <path
        stroke="#A3A3A3"
        strokeWidth="1.2"
        d="M1.531 4.333h8.447c.123.627.449 1.19.923 1.596a2.586 2.586 0 001.68.626c.613 0 1.206-.221 1.68-.626.475-.405.8-.969.923-1.596h2.285a.52.52 0 00.375-.163.569.569 0 00.156-.393.569.569 0 00-.156-.393.52.52 0 00-.375-.162h-2.285a2.804 2.804 0 00-.923-1.596A2.586 2.586 0 0012.581 1c-.612 0-1.205.221-1.68.626-.474.405-.8.968-.923 1.596H1.531a.52.52 0 00-.375.162.569.569 0 00-.156.393c0 .148.056.289.156.393a.52.52 0 00.375.163zm11.05-2.223c.423 0 .828.176 1.127.489.3.312.467.736.467 1.178 0 .442-.168.866-.467 1.179a1.56 1.56 0 01-1.127.488 1.56 1.56 0 01-1.127-.488 1.706 1.706 0 01-.466-1.179c0-.442.167-.866.466-1.178a1.56 1.56 0 011.127-.489zM1.531 12.778h2.39c.124.628.45 1.191.924 1.596a2.586 2.586 0 001.68.626c.612 0 1.206-.221 1.68-.626s.8-.968.923-1.596h8.34a.52.52 0 00.376-.162.57.57 0 00.156-.393.569.569 0 00-.156-.393.52.52 0 00-.375-.163h-8.34a2.803 2.803 0 00-.924-1.596 2.586 2.586 0 00-1.68-.626c-.612 0-1.206.221-1.68.626s-.8.969-.923 1.596h-2.39a.52.52 0 00-.376.163.569.569 0 00-.156.393c0 .147.056.288.156.393a.52.52 0 00.375.162zm4.994-2.222c.423 0 .828.176 1.127.488.299.313.467.737.467 1.179 0 .442-.168.866-.467 1.178a1.56 1.56 0 01-1.127.489 1.56 1.56 0 01-1.127-.489 1.706 1.706 0 01-.467-1.178c0-.442.168-.866.467-1.179a1.56 1.56 0 011.127-.488z"
      ></path>
    </svg>
  );
}

export default Sets;
