import React from "react";

function Dinein() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#00E384" rx="15"></rect>
      <path
        fill="#fff"
        d="M18.495 14.147l.908-.058h.005a2.14 2.14 0 001.595-.93l.718-1.055a4.252 4.252 0 00.682-3.12.963.963 0 00-.9-.797 4.251 4.251 0 00-3.016 1.05l-.96.84a2.14 2.14 0 00-.733 1.695v.005l.052.908c.02.355-.11.704-.356.959l-7.5 7.705c-.201.21-.305.497-.293.81.014.343.172.68.422.903a1.295 1.295 0 00.948.31 1.09 1.09 0 00.77-.388l6.748-8.368a1.28 1.28 0 01.91-.469zm-1.27.177l-6.749 8.368a.631.631 0 01-.446.218.825.825 0 01-.603-.195.825.825 0 01-.266-.575.63.63 0 01.162-.468l7.5-7.706c.336-.347.513-.824.486-1.307l-.052-.906a1.678 1.678 0 01.575-1.327l.96-.84a3.792 3.792 0 012.688-.936.498.498 0 01.466.412 3.79 3.79 0 01-.608 2.781l-.718 1.054c-.287.42-.742.687-1.249.73l-.906.057c-.482.031-.934.264-1.24.64z"
      ></path>
      <path
        fill="#fff"
        d="M22.035 21.292l-5.029-4.581a.232.232 0 10-.312.343l5.028 4.58a.63.63 0 01.19.458.824.824 0 01-.23.59.823.823 0 01-.59.231.63.63 0 01-.458-.19l-4.482-4.92a.232.232 0 00-.342.313l4.482 4.92a1.09 1.09 0 00.815.341c.336 0 .672-.136.902-.367.237-.237.374-.583.367-.927a1.091 1.091 0 00-.34-.791zM9.797 14.104c.384.353.882.554 1.404.567h.004l.91.003c.356.001.697.152.936.412l.401.44a.23.23 0 00.327.016.232.232 0 00.016-.328l-.402-.44a1.744 1.744 0 00-1.276-.564l-.908-.003a1.684 1.684 0 01-1.087-.435l-2.639-3.136a.08.08 0 01.062-.131.08.08 0 01.056.023l2.402 2.401a.61.61 0 00.86 0l.283-.282.5-.501h.001l.282-.282a.61.61 0 000-.861L9.528 8.6a.08.08 0 01.108-.118l3.136 2.639c.27.298.425.684.435 1.088l.003.907c.002.484.207.95.564 1.277l.43.391a.232.232 0 10.312-.342l-.429-.391a1.28 1.28 0 01-.413-.936l-.003-.91V12.2a2.147 2.147 0 00-.589-1.424L9.934 8.129a.544.544 0 00-.734.8l2.402 2.402a.145.145 0 010 .205l-.119.118-2.676-2.676a.232.232 0 10-.328.328l2.676 2.676-.173.173L8.306 9.48a.232.232 0 00-.328.328l2.676 2.676-.118.118a.145.145 0 01-.205 0l-2.402-2.4a.54.54 0 00-.769 0 .54.54 0 00-.031.734l2.648 3.147a.212.212 0 00.02.021z"
      ></path>
    </svg>
  );
}

export default Dinein;