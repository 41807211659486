import React from 'react'
import '../Cafe/Counter.css'
import './Settings.css'
import Colorpicker from './Colorpicker'
import { useState } from 'react'
import mainlogo from '../../../imgs/mainlogo.png'
// import { SwatchesPicker } from 'react-color';


function Design() {

    const [state, updateState] = React.useState("#fff");

    const handleInput = (e) => {
        updateState(e.target.value);
}

    return (
    
    <div className="Contain">

        <div className='dblsections'>
             <div className='fieldss'>
                <h1 className='userTitle'>Dashboard Logo</h1>
                <div className='Imagepicker'>
                    <div className='imgee'>
                        <img src={mainlogo} alt="dash logo" />
                    </div>
                    <div className='Imagebtn'>
                        <button>Choose new one</button>
                        <button 
                        style={{backgroundColor: '#A3A3A3'}}
                        >
                            Remove This</button>
                    </div>
                </div>
                            
             </div> 
             <div className='fieldss'>
                <h1 className='userTitle'>App Logo</h1>
                <div className='Imagepicker'>
                    <div className='imgee'>
                        <img src={mainlogo} alt="dash logo" />
                    </div>
                    <div className='Imagebtn'>
                        <button>Choose new one</button>
                        <button 
                        style={{backgroundColor: '#A3A3A3'}}
                        >
                            Remove This</button>
                    </div>
                </div>
                            
             </div> 
             <div className='fieldss'>
                <h1 className='userTitle'>Favicon Logo</h1>
                <div className='Imagepicker'>
                    <div className='imgee'>
                        <img src={mainlogo} alt="dash logo" />
                    </div>
                    <div className='Imagebtn'>
                        <button>Choose new one</button>
                        <button 
                        style={{backgroundColor: '#A3A3A3'}}
                        >
                            Remove This</button>
                    </div>
                </div>
                            
             </div> 
             
             
             <div className='fieldss'>
                <h1 className='userTitle'>Theme Color</h1>
                <Colorpicker value={state} onChange={handleInput} />
            </div > 
        </div>
        <div className="setbtn"> 
            <button className='Savebtn'>
                <h6 className='Countertext'>Save Update</h6>
            </button>
        </div>
    </div>
  )
}

export default Design;