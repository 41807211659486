import React from "react";

function PUicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
      viewBox="0 0 41 41"
    >
      <circle
        cx="20.5"
        cy="20.5"
        r="19.75"
        stroke="#F6951F"
        strokeWidth="1.5"
      ></circle>
      <path
        fill="#FFEBD2"
        d="M26.87 12.242H13.392v1.089l.224.512.992.192c.16 1.793 1.44 5.186 2.753 5.858 1.05.538.822 1.611.576 2.08-2.766 2.331-3.372 5.176-3.329 6.307h-.512l-.48.32-.224.416v1.057h13.476v-.256l-.224-1.217-1.12-.32c0-3.483-2.155-5.527-3.233-6.114-.41-.743-.171-1.547 0-1.857 2.56-1.946 3.222-4.994 3.233-6.274h.512l.416-.192.416-.512v-1.089z"
      ></path>
      <path
        fill="#fff"
        d="M20.69 16.14c-1.43.864-2.794.956-3.297.894.217 1.03 1.863 2.455 2.924 2.254 1.379-.26 2.559-2.155 2.702-2.813a1.96 1.96 0 00-2.329-.335zM20.011 25.986c-1.332.282-2.381 1.938-2.523 2.059v.242h5.41c-.518-.861-1.82-2.527-2.887-2.301z"
      ></path>
      <path
        fill="#F6951F"
        d="M22.746 20.365c1.77-1.341 2.973-3.741 3.035-6.02h.25c.646 0 1.171-.527 1.171-1.173v-.885a.287.287 0 00-.287-.287H13.287a.287.287 0 00-.287.287v.885c0 .646.526 1.172 1.172 1.172h.25c.061 2.28 1.265 4.68 3.034 6.021.256.194.404.494.404.823 0 .329-.148.629-.404.823-1.77 1.342-2.973 3.741-3.035 6.021h-.249c-.646 0-1.172.526-1.172 1.172v.885c0 .159.129.287.287.287h13.628a.287.287 0 00.287-.287v-.885c0-.646-.526-1.172-1.172-1.172h-.25c-.06-2.28-1.264-4.68-3.034-6.02a1.022 1.022 0 01-.403-.824c0-.329.147-.629.403-.823zm-9.172-7.193v-.598h13.054v.598c0 .33-.268.598-.598.598H14.172a.598.598 0 01-.598-.598zm13.054 16.032v.598H13.574v-.598c0-.33.268-.598.598-.598H26.03c.33 0 .598.268.598.598zm-6.527-2.98c.13.083.267.156.41.215.68.284 1.312.842 1.814 1.593h-4.448c.502-.751 1.135-1.309 1.815-1.593.143-.06.28-.132.41-.215zm1.667-5.036c0 .51.23.977.631 1.28 1.634 1.24 2.746 3.455 2.808 5.564h-2.205c-.59-1.005-1.39-1.756-2.27-2.123a1.997 1.997 0 01-.344-.184V24.39a.287.287 0 00-.574 0v1.335a2.002 2.002 0 01-.343.184c-.88.367-1.681 1.118-2.27 2.123h-2.205c.061-2.109 1.173-4.325 2.807-5.563.4-.304.63-.77.63-1.28s-.229-.978-.63-1.282c-1.634-1.238-2.746-3.454-2.807-5.563h10.21c-.06 2.11-1.173 4.325-2.807 5.563-.4.304-.63.771-.63 1.281zm-1.38.514v.39a.287.287 0 01-.574 0v-.39a.287.287 0 01.574 0zm2.84-5.426c-1.357-.992-2.363-.51-3.335-.043-.72.345-1.463.702-2.43.512a.287.287 0 00-.304.425c.593 1.04 1.414 1.818 2.312 2.193a2 2 0 01.343.184v.72a.287.287 0 10.574 0v-.72c.108-.072.223-.134.344-.184 1.054-.44 1.997-1.437 2.588-2.736a.287.287 0 00-.092-.35zm-2.717 2.557c-.143.06-.28.132-.41.216a2.585 2.585 0 00-.41-.216c-.637-.266-1.235-.776-1.722-1.46.858.009 1.548-.323 2.172-.622.875-.42 1.578-.757 2.553-.144-.533 1.06-1.32 1.865-2.183 2.226z"
      ></path>
    </svg>
  );
}

export default PUicon;
