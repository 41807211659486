import React from 'react'
import Toggle from '../UsersManagment/Toggle';

function Tax() {
  return (
    <div className='dblsections'>
        <div className='fields'>
            <label htmlFor="tax">Tax Percentage %</label>
            <input type="number" min="0" max='100' id='tax' placeholder='%' />
        </div>
        <h1 className="userTitle" >Tax Option</h1>
        <div className='Most'>
          <p className='ttl'>Active Tax</p>
          <div className='toggling'>
            <Toggle />
          </div>
        </div>
        <div className="setbtn">
          <button className="Savebtn">
            <h6 className="Countertext">
              Save Update
            </h6>
          </button>
        </div>
    </div>
  )
}

export default Tax;