import { act } from "react-dom/test-utils";
import {
  GET_BRANCHES_FAIL,
  GET_BRANCHES_SUCCESS,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  ITEM_LIST_FAIL,
  ITEM_LIST_SUCCESS,
} from "../Actions/Constants";
const initialState = {
  merchant: [],
  branches: [],
  error: false,
  loading: true,
};
export const branchData = (state = initialState, action) => {
  let { type, payload } = action;
  // console.log("payloaddddddddddddddddddddddddd", payload);
  switch (action.type) {
    case GET_BRANCHES_SUCCESS:
      const { branch, ...obj } = payload;
      // console.log("branches from reducer", branch);
      return {
        ...state,
        branches: [...branch],
        merchant: [obj],
        loading: false,
      };
    case GET_BRANCHES_FAIL:
      console.log("hello from fail");
      return {
        ...state,
        items: [],
        error: true,
      };
    default:
      console.log("hello from default");
      return {
        ...state,
        items: ["hello from default"],
        error: true,
      };
  }
};
