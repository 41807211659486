import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import {
  GET_KIOSK_DATA_FAIL,
  GET_KIOSK_DATA_PENDING,
  GET_KIOSK_DATA_SUCCESS,
} from "../Actions/Constants";

function* getKioskRecord(action) {
  // console.log("called", action.kioskId);
  let data = yield axios.get(
    `http://159.69.211.133:3000/kiosk/${action.kioskId}`
  );
  console.log("dataaaaaaaa", data);
  if (data.status === 200) {
    console.log("data", data);
    yield put({ type: GET_KIOSK_DATA_SUCCESS, payload: data.data });
  } else {
    yield put({ type: GET_KIOSK_DATA_FAIL });
  }
}

function* kioskSaga() {
  yield takeEvery(GET_KIOSK_DATA_PENDING, getKioskRecord);
}

export default kioskSaga;
