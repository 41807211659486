import React from "react";
import "../Dashboard/Maindash.css";
import "./Users.css";
import Usersdata from "./Usersdata";
// import { Scrollbars } from 'react-custom-scrollbars';

const branchData = [
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
  {
    Name: "Ar Owaisi",
    Email: "Arowaisi49@gmail.com",
    Phone: "+923126317102",
    Role: "Kitchen User",
    Addeddate: "2022-04-04",
    Action: "Ready",
  },
];

function Userlist() {
  return (
    <div className="tab">
      <div className="App1">
        <div className="Table-top">
          <div className="Table-head">
            <h5>Name</h5>
          </div>
          <div className="Table-head">
            <h5>Email</h5>
          </div>
          <div className="Table-head">
            <h5>Phone Number</h5>
          </div>
          <div className="Table-head">
            <h5>Role</h5>
          </div>
          <div className="Table-head">
            <h5>Added Date</h5>
          </div>
          <div className="Table-head">
            <h5>Action</h5>
          </div>
        </div>
      </div>
      {branchData.map((val, key) => {
        return (
          <div className="App1">
            <div key={key} className="Table">
              <Usersdata
                Name={val.Name}
                Email={val.Email}
                Phone={val.Phone}
                Role={val.Role}
                Addeddate={val.Addeddate}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Userlist;
