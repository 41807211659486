import React, { useState } from "react";
import Select from "react-select";
import Branchs from "../../Svg/Branchs";

const placeholderKiosk = (
  <div
    className="MBranch"
    style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
  >
    <Branchs />
    <span className="Branchtlt" style={{ marginLeft: 10 }}>
      Select Branch
    </span>
  </div>
);

function Dropdown({ branchList, selectedBranch, setSelectedBranch }) {
  const handleChange = (e) => {
    setSelectedBranch(e);
  };
  // console.log("branchList", branchList);
  // console.log("branchListfrom dropdown", branchList);
  return (
    <div className="List">
      <Select
        placeholder={placeholderKiosk}
        autosize={true}
        value={selectedBranch}
        options={branchList}
        onChange={handleChange}
        components={{
          IndicatorSeparator: () => null,
        }}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginLeft: 10 }}>{e.branchName}</span>
          </div>
        )}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#FFD39E",
            primary: "#FF8C00",
            primary50: "#FFD39E",
          },
        })}
      />
    </div>
  );
}

export default Dropdown;
