import Search from "../../../../Svg/Search";
import Plus from "../../../../Svg/Plus";
import "./../Itemslist/Itemslist.css";
import Orderno from "../../../../Svg/Orderno";
import AddSides from "./AddSides";
import { useState } from "react";
import Sidesdata from "./Sidesdata";

const Sidedata = [
  {
    image: "./Imagesfood/Food1.png",
    SName: "Fast Food Sides",
    SType: "Want SomeThing More!",
    Nitems: "21",
    items: "Zinger Burger, Paratha Roll etc...",
    Action: "Ready",
  },
];

function Sides({ sides, kioskId, itemList }) {
  console.log("sssssssss", sides);
  const [OpenModal7, setOpenModal7] = useState(false);

  return (
    <div className="itemslist">
      <div className="top-bar">
        <div className="custom-search">
          <input type="search" placeholder="Search Anything" />
          <i className="Iconic">
            <Search />
          </i>
        </div>
        <div>
          <button
            className="tablebutton"
            onClick={() => {
              setOpenModal7(true);
            }}
          >
            <Plus />
            <h6 className="tabletext"> Add New Side</h6>
          </button>
        </div>
      </div>
      <div className="tabi">
        <div className="Table-top">
          <div className="Table-head">
            <Orderno />
          </div>
          <div className="Table-head" style={{ justifyContent: "flex-start" }}>
            <h5>Side Name</h5>
          </div>
          <div className="Table-head">
            <h5>Side Type</h5>
          </div>
          <div className="Table-head">
            <h5>No. Items</h5>
          </div>
          <div className="Table-head">
            <h5>Items</h5>
          </div>
          <div className="Table-head">
            <h5>Action</h5>
          </div>
        </div>
        {sides.map((val, key) => {
          return (
            <div className="App1">
              <div key={key} className="Table">
                <Sidesdata
                  image={val.image}
                  // SName={val.SName}
                  SType={val.type}
                  Nitems={val?.items.length}
                  // items={val.items}
                />
              </div>
            </div>
          );
        })}
      </div>
      {OpenModal7 && (
        <AddSides
          kioskId={kioskId}
          itemList={itemList}
          closeModal={setOpenModal7}
        />
      )}
    </div>
  );
}

export default Sides;
