import React from 'react'
import './Settings.css'

const colorpicker = ({value, onChange, ...rest}) => {
  return (
    <div className='colorpicker'>
        <input type="text" value={value} onChange={onChange} {...rest} />
        <input type='color' value={value} onChange={onChange} {...rest}/>
    </div>
  )
}

export default colorpicker;