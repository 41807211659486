import React from "react";

function Orderinfo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 32 32"
    >
      <circle cx="16" cy="16" r="16" fill="#D6FFF2"></circle>
      <path
        fill="#00DC98"
        d="M20.8 12.667c0 .442.169.866.469 1.178.3.313.707.488 1.131.488.424 0 .831-.175 1.131-.488.3-.312.469-.736.469-1.178 0-.442-.169-.866-.469-1.179A1.568 1.568 0 0022.4 11c-.424 0-.831.176-1.131.488-.3.313-.469.737-.469 1.179zm0 6.666c0 .442.169.866.469 1.179.3.312.707.488 1.131.488.424 0 .831-.176 1.131-.488.3-.313.469-.737.469-1.179 0-.442-.169-.866-.469-1.178a1.569 1.569 0 00-1.131-.488c-.424 0-.831.175-1.131.488-.3.312-.469.736-.469 1.178zM16 21c-.424 0-.831-.176-1.131-.488a1.703 1.703 0 01-.469-1.179c0-.442.169-.866.469-1.178.3-.313.707-.488 1.131-.488.424 0 .831.175 1.131.488.3.312.469.736.469 1.178 0 .442-.169.866-.469 1.179-.3.312-.707.488-1.131.488zm-1.6-8.333c0 .442.169.866.469 1.178.3.313.707.488 1.131.488.424 0 .831-.175 1.131-.488.3-.312.469-.736.469-1.178 0-.442-.169-.866-.469-1.179A1.568 1.568 0 0016 11c-.424 0-.831.176-1.131.488-.3.313-.469.737-.469 1.179zM9.6 21c-.424 0-.831-.176-1.131-.488A1.702 1.702 0 018 19.333c0-.442.169-.866.469-1.178.3-.313.707-.488 1.131-.488.424 0 .831.175 1.131.488.3.312.469.736.469 1.178 0 .442-.169.866-.469 1.179-.3.312-.707.488-1.131.488zM8 12.667c0 .442.169.866.469 1.178.3.313.707.488 1.131.488.424 0 .831-.175 1.131-.488.3-.312.469-.736.469-1.178 0-.442-.169-.866-.469-1.179A1.568 1.568 0 009.6 11c-.424 0-.831.176-1.131.488-.3.313-.469.737-.469 1.179z"
      ></path>
    </svg>
  );
}

export default Orderinfo;