import React from "react";

function Closebtn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 3L3 15m12 0L3 3l12 12z"
      ></path>
    </svg>
  );
}

export default Closebtn;