import Search from "../../../../Svg/Search";
import Plus from "../../../../Svg/Plus";
import "./Itemslist.css";
import Orderno from "../../../../Svg/Orderno";
import { useState } from "react";
import Itemsdata from "./Itemsdata";
import Additems from "./AddItems";
import { useDispatch, useSelector } from "react-redux";
import { itemAction } from "../../../../redux/Actions/ItemAction";
import { getBranches } from "../../../../redux/Actions/action";

function Itemslist({
  itemList,
  categories,
  sides,
  extras,
  Ingredients,
  kioskId,
}) {
  const [OpenModal4, setOpenModal4] = useState(false);
  const dispath = useDispatch();
  // dispath(itemAction());
  // const state = useSelector((state) => state.branchData);
  // console.log("state", state);
  const Itemdata = [
    {
      image: "./Imagesfood/Food1.png",
      Name: "Special Zinger burger",
      Category: "Burgers",
      Price: "200KR",
      Type: "Fast Food",
      Attributes: "Most Selling",
    },
  ];

  return (
    <div className="itemslist">
      <div className="top-bar">
        <div className="custom-search">
          <input type="search" placeholder="Search Anything" />
          <i className="Iconic">
            <Search />
          </i>
        </div>
        <div>
          <button
            className="tablebutton"
            onClick={() => {
              setOpenModal4(true);
            }}
          >
            <Plus />
            <h6 className="tabletext"> Add New Items</h6>
          </button>
        </div>
      </div>
      <div className="tabi">
        <div className="Table-top">
          <div className="Table-head">
            <Orderno />
          </div>
          <div className="Table-head" style={{ justifyContent: "flex-start" }}>
            <h5>Name</h5>
          </div>
          <div className="Table-head">
            <h5>Item Category</h5>
          </div>
          <div className="Table-head">
            <h5>Price</h5>
          </div>
          <div className="Table-head">
            <h5>Type</h5>
          </div>
          <div className="Table-head">
            <h5>Attributes</h5>
          </div>
          <div className="Table-head">
            <h5>Action</h5>
          </div>
        </div>
        {itemList.map((val, key) => {
          return (
            <div className="App1">
              <div key={key} className="Table">
                <Itemsdata
                  image={val.image}
                  Name={val.name}
                  Category={val?.category?.name}
                  Price={val.price}
                  Type={val.itemType}
                  Attributes={val.sellingStatus}
                />
              </div>
            </div>
          );
        })}
      </div>
      {OpenModal4 && (
        <Additems
          extras={extras}
          ingredients={Ingredients}
          categories={categories}
          kioskId={kioskId}
          sides={sides}
          closeModal={setOpenModal4}
        />
      )}
    </div>
  );
}

export default Itemslist;
