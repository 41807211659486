import React, { useState, useEffect } from "react";
import Closebtn from "../../../Svg/Closebtn";
import "./Modal.css";
import { Bars } from "react-loader-spinner";

function AddTable({ closeModal }) {
  const [tableData, setTableData] = useState({
    TableNo: "",
    Chairs: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTableData({ ...tableData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(tableData);
    setIsLoading(true);
    // validate form data and submit to server or database
  };

  useEffect(() => {
    if (isLoading) {
      // simulate a long loading process
      setTimeout(() => {
        setIsLoading(false);
        closeModal(false);
      }, 5000);
    }
  }, [isLoading]);

  return (
    <div className="Modalbg">
      <div className="ModalContainer">
        <div className="closebtn">
          <button onClick={() => closeModal(false)}>
            <Closebtn />
          </button>
        </div>
        <div className="ModalTitle">Add Table</div>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <div className="Modalbtn ct dr">
              <Bars
                height="50"
                width="50"
                color="#FF8C00"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <p className="loadtlt">Adding Table</p>
            </div>
          ) : (
            <div>
              <div className="Modalbody">
                <div>
                  <label htmlfor="Table">Table Number</label>
                  <input
                    type="number"
                    min="1"
                    max="100"
                    name="TableNo"
                    value={tableData.TableNo}
                    onChange={handleChange}
                    id="Table"
                    placeholder="Table Number"
                    required
                  />
                </div>
                <div>
                  <label htmlfor="No">No. of Chairs</label>
                  <input
                    type="number"
                    min="1"
                    max="100"
                    name="Chairs"
                    value={tableData.Chairs}
                    onChange={handleChange}
                    id="No"
                    placeholder="No. of Chairs"
                    required
                  />
                </div>
              </div>
              <div className="Modalbtn ct">
                <button type="submit" className="btn">
                  Add Table
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddTable;
