import React from "react";

function Total() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#28A900" rx="15"></rect>
      <path
        fill="#fff"
        d="M15 8a7 7 0 10.001 14.001A7 7 0 0015 8zm0 12.813a5.813 5.813 0 010-11.625 5.813 5.813 0 010 11.624zm.745-6.175l-.397-.093v-2.098c.594.081.961.453 1.024.91.008.062.06.107.123.107h.702a.127.127 0 00.125-.137c-.095-.974-.897-1.599-1.967-1.707v-.51a.125.125 0 00-.125-.126h-.44a.125.125 0 00-.124.125v.516c-1.107.108-1.972.719-1.972 1.86 0 1.056.778 1.565 1.595 1.76l.386.099v2.23c-.69-.093-1.078-.462-1.158-.958a.124.124 0 00-.123-.104h-.722a.125.125 0 00-.125.136c.07.86.722 1.65 2.112 1.752v.49c0 .07.057.126.125.126h.444a.126.126 0 00.125-.127l-.003-.495c1.223-.108 2.098-.763 2.098-1.938-.001-1.084-.69-1.569-1.703-1.819zm-1.072-.254a2.303 2.303 0 01-.234-.078c-.528-.19-.773-.498-.773-.895 0-.567.43-.89 1.007-.964v1.937zm.675 3.194V15.49c.049.014.093.025.138.034.739.225.987.538.987 1.018 0 .61-.459.978-1.125 1.037z"
      ></path>
    </svg>
  );
}

export default Total;
