import React from "react";

function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="7"
      fill="none"
      viewBox="0 0 12 7"
    >
      <path
        fill="#FF8C00"
        d="M1.714 0L6 4.375 10.286 0 12 .875 6 7 0 .875 1.714 0z"
      ></path>
    </svg>
  );
}

export default Arrow;
