import { all, fork } from "redux-saga/effects";
import branchesSaga from "./branchesSage";
import itemSaga from "./itemSaga";
import kioskSaga from "./kioskSaga";
import merchantKitchenDataSaga from "./merchantKitchenDataSaga";

export default function* rootSaga() {
  yield all([
    fork(branchesSaga),
    fork(itemSaga),
    fork(kioskSaga),
    fork(merchantKitchenDataSaga),
  ]);
}
