import React from 'react'
import Orderinfo from '../../../Svg/Orderinfo';
import Ordermode from '../../../Svg/Orderinfo';
import './Orderinfobtn.css'

function Orderinfobtn() {
  return (
    <div>
          <button className='Main-Button-Order'>
             <i className="ordericon-2"> <Orderinfo /> </i>
             <div className="title-button">Orders info</div>
         </button>
    </div>
  )
}

export default Orderinfobtn;