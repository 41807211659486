import React from "react";

function Orderno() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#E4F9FF"></circle>
      <path
        fill="#00C2FF"
        d="M13.818 16c.161 0 .315.07.429.195a.702.702 0 01.177.472.702.702 0 01-.177.471.58.58 0 01-.429.195h-3.636a.58.58 0 01-.429-.195.702.702 0 01-.177-.471c0-.177.064-.347.177-.472a.58.58 0 01.429-.195h3.636zm2.425-4c.16 0 .314.07.428.195a.702.702 0 01.178.472.702.702 0 01-.178.471.58.58 0 01-.428.195H7.758a.58.58 0 01-.429-.195.702.702 0 01-.177-.471c0-.177.063-.347.177-.472A.58.58 0 017.758 12h8.485zm1.818-4c.16 0 .314.07.428.195a.702.702 0 01.178.472.702.702 0 01-.178.471.58.58 0 01-.428.195H5.939a.58.58 0 01-.428-.195.702.702 0 01-.178-.471c0-.177.064-.347.178-.472A.58.58 0 015.939 8h12.122z"
      ></path>
    </svg>
  );
}

export default Orderno;
