import React from "react";

function Table() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#FFF2E2"></circle>
      <path
        fill="#F6951F"
        d="M13.136 10.596a.677.677 0 00-.074-.32.719.719 0 00-.2-.237.905.905 0 00-.28-.145 1.047 1.047 0 00-.646.003.836.836 0 00-.273.153.767.767 0 00-.188.237.666.666 0 00-.071.31.64.64 0 00.075.308.79.79 0 00.195.238c.08.066.172.117.277.153a.984.984 0 00.646-.004.935.935 0 00.276-.156.745.745 0 00.192-.238.651.651 0 00.071-.302z"
      ></path>
      <path
        fill="#F6951F"
        d="M16.54 14.559V5H8v9.559h3.816v5.04H10.38v.908h3.778v-.909h-1.435v-5.04h3.816zm-4.277-2.352c-.251 0-.488-.039-.71-.117a1.799 1.799 0 01-.582-.33 1.552 1.552 0 01-.39-.504 1.435 1.435 0 01-.143-.64c0-.141.024-.272.071-.393.048-.12.11-.23.185-.33a1.57 1.57 0 01.532-.448 1.191 1.191 0 01-.447-.383.937.937 0 01-.184-.56c0-.228.05-.428.152-.6a1.43 1.43 0 01.398-.437 1.95 1.95 0 012.254-.007c.16.118.293.264.397.436.104.173.156.373.156.6a.91.91 0 01-.05.295c-.032.097-.079.187-.138.27a1.23 1.23 0 01-.465.387 1.587 1.587 0 01.554.465c.076.101.136.214.181.337a1.38 1.38 0 01-.078 1.044c-.097.189-.23.352-.398.49a1.81 1.81 0 01-.585.315c-.223.073-.46.11-.71.11z"
      ></path>
      <path
        fill="#F6951F"
        d="M11.79 8.975a.836.836 0 00.727.114.73.73 0 00.232-.117.634.634 0 00.167-.189.507.507 0 00.064-.259.497.497 0 00-.21-.419.826.826 0 00-.507-.156.787.787 0 00-.497.16.508.508 0 00-.206.422c0 .1.021.186.064.26a.591.591 0 00.167.184z"
      ></path>
      <path
        fill="#F6951F"
        d="M9.287 6.492H15.252V12.754999999999999H9.287z"
      ></path>
    </svg>
  );
}

export default Table;
