import React from "react";

function CounterManagment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      fill="none"
      viewBox="0 0 16 18"
    >
      <path
        fill="#A3A3A3"
        d="M8.572 14.955V12H7.429v2.955L5.95 13.478l-.808.808L8 17.143l2.857-2.857-.808-.808-1.477 1.477z"
      ></path>
      <path
        fill="#A3A3A3"
        d="M16 6.286H0v4.571h1.143v5.714h1.143v-5.714h11.428v5.714h1.143v-5.714H16V6.286zm-1.143 3.428H1.143V7.43h13.714v2.285zM7.43 2.187v2.956H8.57V2.187l1.478 1.478.808-.808L8 0 5.143 2.857l.808.808 1.478-1.478z"
      ></path>
    </svg>
  );
}

export default CounterManagment;
