import React from "react";
import "./Counter.css";
import Plus from "../../../Svg/Plus";
import Count from "../../../Svg/Count";
import { useState } from "react";
import AddCounter from "./AddCounter";

const Counters = [
  { title: "01" },
  { title: "02" },
  { title: "03" },
  { title: "04" },
  { title: "05" },
  { title: "06" },
  { title: "07" },
  { title: "08" },
  { title: "09" },
];

const Counter = () => {
  const [OpenModal1, setOpenModal1] = useState(false);

  return (
    <div className="Counteroptions">
      <button
        onClick={() => {
          setOpenModal1(true);
        }}
        className="Counterbutton"
      >
        <Plus />
        <h6 className="Countertext">Add Counter</h6>
      </button>

      <div className="Countersection">
        {Counters.map((tal, key) => {
          return (
            <div key={key} className="Counter-box">
              <i className="Counteroption-icon">
                {" "}
                <Count />{" "}
              </i>
              <p className="Counternumbering"> {tal.title} </p>
            </div>
          );
        })}
      </div>
      <button className="CounterSavebutton">
        <h6 className="Countertext">Save Update</h6>
      </button>

      {OpenModal1 && <AddCounter closeModal={setOpenModal1} />}
    </div>
  );
};

export default Counter;
