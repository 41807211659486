import React from "react";

function Users() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        stroke="#A3A3A3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7 6.333A2.667 2.667 0 107 1a2.667 2.667 0 000 5.333zM13 14.334a6 6 0 00-12 0"
      ></path>
    </svg>
  );
}

export default Users;