import { ADD_TO_CART, REMOVE_FROM_CART,EMPTY_CART } from "../Actions/Constants"

export const cartData=(data=[],action)=>{
    switch(action.type){
      case ADD_TO_CART:
            return [action.data,...data]
      case REMOVE_FROM_CART:
        data.length=data.length ? data.length-1:[]
        console.log("data",data);
        return [...data]
        case EMPTY_CART:
            return []
      default:
        console.log("no type matched");
        return action.type
      
    }
}