import React, { useState } from 'react'
import Header from '../../Header/Header';
import './MainCafe.css'
import Menubar from './Menubar';
import Table from './Table';
import Counter from './Counter';
import TableManagment from '../../../Svg/TableManagment';
import './Menubar.css'
import CounterManagment from '../../../Svg/CounterManagment';
import Sidebar from '../../Sidebar/Sidebar';

const MainCafe = () => {

  const [ToggleOrders,setToggleOrders] = useState(1);
  const OrderTabs = (index) => {
    setToggleOrders(index);
  }


  return (
    <div className="AppBack">
        <div className='Sider' > <Sidebar/></div>
    <div className='Container'>
     <Header title="Cafe Management" />
     <div className='Maincard'>
        <div className='CafeDisply'>
          <div className='menubar'>
            <div className="menus">
              <button 
                className={ToggleOrders === 1 ? "menuitemss actives" : "menuitemss" }
                onClick={() => OrderTabs(1)}
                >
                  <i className={ToggleOrders=== 1? 'iconss activateds' : 'iconss'}> <TableManagment /> </i>            
                  <span>Table Managment</span>
              </button> 
              <button 
                className={ToggleOrders === 2 ? "menuitemss actives" : "menuitemss" }
                onClick={() => OrderTabs(2)}
                >
                  <i className={ToggleOrders=== 2? 'iconss activateds' : 'iconss'}> <CounterManagment /> </i>            
                  <span>Counter Managment</span>
              </button>          
              
            </div>
          </div>
          <div className='Menucard'>
            <div id='sec' className={ToggleOrders === 1 ? "section activess" : "section" } >
              <div className="sec">
                <i className='iconss'> <TableManagment /> </i> 
                <h5 className='Menucardtitle'> Table Managment</h5>
              </div>
              <Table/> 
            </div>
            <div id='sec' className={ToggleOrders === 2 ? "section activess" : "section" } >
              <div className="sec">
                <i className='iconss'> <CounterManagment /> </i> 
                <h5 className='Menucardtitle'> Counter Managment</h5>
              </div>
              <Counter/>
            </div>
            

          </div>
        </div>
     </div>
    </div>
    </div>
  )
}

export default MainCafe;