import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import { createStoreHook } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./redux/reducers/index";
import itemSaga from "./redux/Saga/itemSaga";
import branchesSaga from "./redux/Saga/branchesSage";
import rootSaga from "./redux/Saga/rootsaga";
const initialState = {};

const dummyreducer = () => {
  return 100;
};
// const store=createStore(
// rootReducer,
// // initialState
// )
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: () => [sagaMiddleware],
});
sagaMiddleware.run(rootSaga);
export default store;
