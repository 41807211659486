import React from "react";
import { useState, useEffect } from "react";
import Closebtn from "../../../../Svg/Closebtn";
import Camera from "../../../../Svg/Camera";
import Sdelete from "../../../../Svg/Sdelete";
import Select from "react-select";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { getKioskData } from "../../../../redux/Actions/action";
import { useDispatch } from "react-redux";

const sidesType = [
  {
    value: "want_something_more",
    label: "want something more",
  },
  {
    value: "coffee_or_ice_cream",
    label: "Coffee or Ice cream",
  },
];

function AddSides({ closeModal, itemList, kioskId }) {
  const [side, setSide] = useState({
    name: "",
    type: "",
    items: [],
    kiosk: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSide({ ...side, [name]: value });
  };

  const handleSelectChangeType = (selected, name) => {
    setSide({ ...side, [name]: selected?.value, kiosk: kioskId });
  };

  const handleSelectChange = (selected, name) => {
    var values = selected.map(function (obj) {
      let value = obj["id"];
      return { id: value };
    });
    console.log(values);
    setSide({ ...side, [name]: values });
  };
  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   const url = URL.createObjectURL(file);
  //   const image = new Image();
  //   image.src = url;
  //   image.onload = () => {
  //     const { naturalWidth, naturalHeight } = image;
  //     if (naturalWidth !== 400 || naturalHeight !== 400) {
  //       // setSide({
  //       //   ...side,
  //       //   error: "Low Resolution or Wrong Size (MUST BE 400X400) ",
  //       // });
  //       alert("image error");
  //     } else {
  //       setSide({ ...side, image: file, kiosk: kioskId });
  //     }
  //   };
  // };

  const handleImageDelete = () => {
    setSide({ ...side, image: "" });
  };
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(side);
    // console.log(formData);
    try {
      const newSide = await axios.post(
        "http://159.69.211.133:3000/sides",
        side
      );
      console.log("newCat", newSide);
      if (newSide.status === 201) {
        dispatch(getKioskData(kioskId));
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(true);
    // do something with the form values
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      // simulate a long loading process
      setTimeout(() => {
        setIsLoading(false);
        closeModal(false);
      }, 5000);
    }
  }, [isLoading]);

  return (
    <div className="Modalbg">
      <div className="ModalContainer" style={{ width: "550px" }}>
        <div className="closebtn">
          <button onClick={() => closeModal(false)}>
            <Closebtn />
          </button>
        </div>
        <div className="ModalTitle">Create New Side</div>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <div className="Modalbtn ct dr">
              <Bars
                height="50"
                width="50"
                color="#FF8C00"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <p className="loadtlt">Adding Sides</p>
            </div>
          ) : (
            <div>
              <div className="Modalbody">
                <div className="dblsection">
                  {/* {side.image ? (
                    <div className="Imgs center">
                      <img
                        className="iimg"
                        src={URL.createObjectURL(side.image)}
                        alt="Selected"
                      />
                      <button
                        type="delete"
                        className="imgdel"
                        onClick={handleImageDelete}
                      >
                        <Sdelete />
                      </button>
                    </div>
                  ) : (
                    <div className="Imgs">
                      <label htmlFor="imgs">
                        <div className="addmore">
                          <i>
                            <Camera />
                          </i>
                          <p className="mains tlt">Choose Side Image</p>
                          {side.error ? (
                            <p className="error">{side.error}</p>
                          ) : (
                            <p className="mains line">MUST BE PNG (400X400)</p>
                          )}
                        </div>
                      </label>
                      <input
                        className="inp"
                        id="imgs"
                        name="image"
                        value={side.image}
                        onChange={handleImageChange}
                        type="file"
                        accept="image/png"
                      />
                    </div>
                  )} */}
                  <div className="snglsection">
                    <div className="fields">
                      <label for="sname">Side Name</label>
                      <input
                        name="name"
                        value={side.name}
                        onChange={handleChange}
                        type="text"
                        id="sname"
                        placeholder="Side Name"
                        required
                      />
                    </div>
                    <div className="fields">
                      <label for="stype">Side Type</label>
                      <Select
                        value={side.type}
                        onChange={(selected) =>
                          handleSelectChangeType(selected, "type")
                        }
                        autosize={true}
                        getOptionLabel={(e) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginLeft: 10 }}>{e.label}</span>
                          </div>
                        )}
                        options={sidesType}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        placeholder="Side Type"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#FFD39E",
                            primary: "#FF8C00",
                            primary50: "#FFD39E",
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="dblsection">
                <div className="fields btm">
                  <h1 className="userTitle">Side Items</h1>
                  <Select
                    value={side.items}
                    onChange={(selected) =>
                      handleSelectChange(selected, "items")
                    }
                    isMulti
                    isClearable={false}
                    autosize={true}
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {e.icon}
                        <span style={{ marginLeft: 10 }}>{e.name}</span>
                      </div>
                    )}
                    options={itemList}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Side Item"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#FFD39E",
                        primary: "#FF8C00",
                        primary50: "#FFD39E",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="Modalbtn">
                <button
                  id="Cancel"
                  className="btn"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn">
                  Create Side
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddSides;
