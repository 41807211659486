import React from "react";
import { useState, useEffect } from "react";
import Closebtn from "../../../../Svg/Closebtn";
import Camera from "../../../../Svg/Camera";
import Sdelete from "../../../../Svg/Sdelete";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getMerchantKitchenData } from "../../../../redux/Actions/action";

function AddIngredient({ closeModal }) {
  const [ingredient, setIngredient] = useState({
    image: "",
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIngredient({ ...ingredient, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    const image = new Image();
    image.src = url;
    image.onload = () => {
      const { naturalWidth, naturalHeight } = image;
      if (naturalWidth !== 400 || naturalHeight !== 400) {
        // setIngredient({
        //   ...ingredient,
        //   error: "Low Resolution or Wrong Size (MUST BE 400X400) ",
        // });
        alert("image error");
      } else {
        setIngredient({ ...ingredient, image: file });
      }
    };
  };

  const handleImageDelete = () => {
    setIngredient({ ...ingredient, image: "" });
  };
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(ingredient);
    const formData = new FormData();
    formData.append("name", ingredient.name);
    formData.append("image", ingredient.image);
    // formData.append("items", JSON.stringify(deal.items));
    formData.append("merchant", "60b1f6cf-f894-4ef2-b72f-9c19811e0e4d");
    console.log(formData);
    // formData.append("price", deal.price);
    // formData.append("item", deal.items);
    try {
      const newDeal = await axios.post(
        "http://159.69.211.133:3000/ingredients",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (newDeal.status === 201) {
        dispatch(
          getMerchantKitchenData("60b1f6cf-f894-4ef2-b72f-9c19811e0e4d")
        );
      }
      // console.log("newCat", newCategory);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(true);
    // do something with the form values
  };

  // Loading Funtion

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
        closeModal(false);
      }, 5000);
    }
  }, [isLoading]);

  return (
    <div className="Modalbg">
      <div className="ModalContainer" style={{ width: "350px" }}>
        <div className="closebtn">
          <button onClick={() => closeModal(false)}>
            <Closebtn />
          </button>
        </div>
        <div className="ModalTitle">Create New Ingredient</div>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <div className="Modalbtn ct dr">
              <Bars
                height="50"
                width="50"
                color="#FF8C00"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <p className="loadtlt">Adding Ingredient</p>
            </div>
          ) : (
            <div>
              <div className="Modalbody">
                {/* First Section Images And Category name and Item Type */}
                <div className="dblsection2">
                  {ingredient.image ? (
                    <div className="Imgs w center">
                      <img
                        className="iimg"
                        src={URL.createObjectURL(ingredient.image)}
                        alt="Selected"
                      />
                      <button
                        type="delete"
                        className="imgdel"
                        onClick={handleImageDelete}
                      >
                        <Sdelete />
                      </button>
                    </div>
                  ) : (
                    <div className="Imgs w">
                      <label htmlFor="imgs">
                        <div className="addmore">
                          <i>
                            <Camera />
                          </i>
                          <p className="mains tlt">Choose Ingredient Image</p>
                          {ingredient.error ? (
                            <p className="error">{ingredient.error}</p>
                          ) : (
                            <p className="mains line">MUST BE PNG (400X400)</p>
                          )}
                        </div>
                      </label>
                      <input
                        className="inp"
                        id="imgs"
                        name="image"
                        // value={category.catimg}
                        onChange={handleImageChange}
                        type="file"
                        accept="image/png"
                      />
                    </div>
                  )}

                  <div className="fields">
                    <label for="ItemN">Ingredient Name</label>
                    <input
                      name="name"
                      value={ingredient.name}
                      onChange={handleChange}
                      type="text"
                      id="ItemN"
                      placeholder="Ingredient Name"
                    />
                  </div>
                </div>
              </div>
              <div className="Modalbtn">
                <button
                  id="Cancel"
                  className="btn"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn">
                  Create Ingredient
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddIngredient;
