import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="11.5" fill="#fff" stroke="#00DC98"></circle>
      <path
        fill="#00DC98"
        d="M10.378 16.235l5.5-5.5-2.613-2.613-5.5 5.5a.594.594 0 00-.155.275L7 17l3.102-.61c.105-.026.2-.08.276-.155zm7.275-7.276a1.184 1.184 0 000-1.674l-.938-.938a1.183 1.183 0 00-1.674 0l-.939.938 2.613 2.613.938-.939z"
      ></path>
    </svg>
  );
}

export default Icon;
