import React from 'react'
import ReactApexChart from "react-apexcharts"
import './Maindash.css'

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    
      series: [{
        name: 'series1',
        data: [31, 40, 28]
    }],
      options: {
        chart: {
          height: 10,
          type: 'area'
        },
        colors: ['#FF7F00'],
              stroke: {
                width: 0,
                curve: 'smooth'
              },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        // xaxis: {
        //   type: 'datetime',
        //   categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        // },
        tooltip: {
          // x: {
          //   format: 'dd/MM/yy HH:mm'
          // },
        },
      },
    
    
    };
  }



  render() {
    return (
      

<div id="chart">
<ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
</div>
    )}
}

export default ApexChart;
