import React from "react";

function Branchs() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#E6FFF4"></circle>
      <path
        fill="#00E384"
        d="M17.059 8.72V6.97a.437.437 0 00-.438-.438H7.38a.437.437 0 00-.438.438V8.72a.437.437 0 00-.41.436v1.8a2.42 2.42 0 00.41 1.359v4.716c0 .24.196.438.438.438h3.746v-2.407h1.75v2.407h3.746a.437.437 0 00.438-.438v-4.716a2.443 2.443 0 00.41-1.358v-1.8a.437.437 0 00-.41-.437zm-.985 7.766H13.75v-1.861a.437.437 0 00-.438-.438h-2.624a.437.437 0 00-.438.438v1.86H7.926v-3.31a2.465 2.465 0 001.086.248 2.47 2.47 0 001.49-.496.009.009 0 01.01 0 2.465 2.465 0 001.492.495 2.47 2.47 0 001.49-.495.009.009 0 01.008 0c.164.122.341.224.53.302.304.129.629.193.962.193.334 0 .656-.064.963-.193.04-.018.082-.035.123-.055v3.312h-.006zm0-7.767H7.926V7.516h8.148v1.203z"
      ></path>
    </svg>
  );
}

export default Branchs;
