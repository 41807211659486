import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="11.5" fill="#fff" stroke="#FF2D2D"></circle>
      <path
        fill="#FF2D2D"
        d="M18 9.767l-.696 1.178-8.441-4.767L9.559 5l2.115 1.192.947-.252 3.013 1.702.257.933L18 9.767zM8 16.638V8.466h3.528l4.823 2.724v5.448c0 .361-.147.708-.408.963a1.407 1.407 0 01-.984.399H9.392c-.37 0-.723-.143-.984-.399A1.347 1.347 0 018 16.638z"
      ></path>
    </svg>
  );
}

export default Icon;
