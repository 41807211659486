import React from "react";
import Filters from "../../../Svg/Filter";
import Header from "../../Header/Header";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./Users.css";
import Search from "../../../Svg/Search";
import Plus from "../../../Svg/Plus";
import Userlist from "./Userlist";
import { useState } from "react";
import AddUser from "./AddUser";
import Sidebar from "../../Sidebar/Sidebar";

const options = [
  {
    value: "james",
    label: "james",
  },
  {
    value: "jamews",
    label: "jamews",
  },
  {
    value: "jamewws",
    label: "jamewws",
  },
];

const UsersManagment = () => {
  const [OpenModal2, setOpenModal2] = useState(false);

  return (
    <div className="AppBack">
      <div className="Sider">
        {" "}
        <Sidebar />
      </div>
      <div className="Container">
        <Header title="User Managment" />
        <div className="MainUsers">
          <div className="Top">
            <div className="filters">
              {/* <Filters />
                <span className="titlefilter">Filter</span> */}
              <div className="custom-select">
                <Select
                  autosize={true}
                  options={options}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Filter"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#FFD39E",
                      primary: "#FF8C00",
                      primary50: "#FFD39E",
                    },
                  })}
                />
              </div>
              <div className="custom-search">
                <input type="search" placeholder="Search Users" />
                <i className="Iconic">
                  <Search />
                </i>
              </div>
            </div>
            <div className="buttonic">
              <button
                onClick={() => {
                  setOpenModal2(true);
                }}
                className="tablebutton"
              >
                <Plus />
                <h6 className="tabletext"> Add New User </h6>
              </button>
            </div>
          </div>
          <div className="userlist">
            <Userlist />
          </div>
        </div>
        {OpenModal2 && <AddUser closeModal={setOpenModal2} />}
      </div>
    </div>
  );
};

export default UsersManagment;
