import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import {
  GET_MERCHANT_KITCHEN_DATA_FAIL,
  GET_MERCHANT_KITCHEN_DATA_PENDING,
  GET_MERCHANT_KITCHEN_DATA_SUCCESS,
} from "../Actions/Constants";
function* getMerchantKitchenData(action) {
  console.log("from saga", action.merchantId);
  let data = yield axios.get(
    `http://159.69.211.133:3000/merchants/KitchenRecord/${action.merchantId}`
  );
  console.log("ingredient record", data);
  if (data.status === 200) {
    yield put({ type: GET_MERCHANT_KITCHEN_DATA_SUCCESS, payload: data.data });
  } else {
    yield put({ type: GET_MERCHANT_KITCHEN_DATA_FAIL });
  }
}
function* merchantKitchenDataSaga() {
  yield takeEvery(GET_MERCHANT_KITCHEN_DATA_PENDING, getMerchantKitchenData);
}
export default merchantKitchenDataSaga;
