import React from "react";

function information() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FF8C00"
        d="M7.969 1.334c-3.659 0-6.636 2.99-6.636 6.667A6.674 6.674 0 008 14.667a6.674 6.674 0 006.667-6.666c0-3.676-3.005-6.667-6.698-6.667zm.031 12a5.34 5.34 0 01-5.333-5.333c0-2.941 2.378-5.334 5.302-5.334 2.958 0 5.364 2.393 5.364 5.334A5.34 5.34 0 018 13.334z"
      ></path>
      <path
        fill="#FF8C00"
        d="M7.333 4.666h1.334v4.667H7.333V4.666zm0 5.333h1.334v1.334H7.333V9.999z"
      ></path>
    </svg>
  );
}

export default information;