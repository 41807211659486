import React from "react";

function Ordertp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#FFEBD2"></circle>
      <path
        fill="#FF8C00"
        d="M8.118 14.95a1.042 1.042 0 01-.31.743 1.06 1.06 0 01-1.154.227 1.06 1.06 0 01-.573-.568 1.042 1.042 0 01.23-1.145 1.063 1.063 0 011.497 0c.198.197.31.464.31.743zm0-4.9a1.042 1.042 0 01-.31.742 1.06 1.06 0 01-1.154.228 1.06 1.06 0 01-.573-.568 1.042 1.042 0 01.23-1.144 1.063 1.063 0 011.497 0c.198.196.31.464.31.742zm4.94 4.9a1.043 1.043 0 01-.31.743 1.06 1.06 0 01-1.153.227 1.06 1.06 0 01-.573-.568 1.043 1.043 0 01.23-1.145 1.063 1.063 0 011.497 0c.198.197.31.464.31.743zm0-4.9a1.043 1.043 0 01-.31.742 1.06 1.06 0 01-1.153.228 1.06 1.06 0 01-.573-.568 1.043 1.043 0 01.23-1.144 1.063 1.063 0 011.497 0c.198.196.31.464.31.742zM18 14.95c0 .279-.112.546-.31.743a1.063 1.063 0 01-1.498 0 1.046 1.046 0 010-1.486 1.063 1.063 0 011.498 0c.198.197.31.464.31.743zm0-4.9c0 .278-.112.545-.31.742a1.063 1.063 0 01-1.498 0 1.046 1.046 0 010-1.484 1.063 1.063 0 011.498 0c.198.196.31.464.31.742z"
      ></path>
    </svg>
  );
}

export default Ordertp;
