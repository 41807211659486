import React from "react";
import "./Header.css";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Dropdown/Button";
import Notification from "../../Svg/Notification";
import Profile from "../../imgs/img2.png";
import Arrow from "../../Svg/Arrow";

const Header = ({ title, branchList, selectedBranch, setSelectedBranch }) => {
  return (
    <div className="Main-header">
      <div className="title">{title}</div>
      <Dropdown
        className="DropDownlist"
        branchList={branchList}
        selectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
      />
      <Button className="button" />
      <div className="Noti">
        <Notification />
      </div>
      <img className="Profile" src={Profile} alt="" />
      <div className="Profile-Name">Mustafa</div>
      <div className="Arrow-Menu">
        <Arrow />
      </div>
    </div>
  );
};

export default Header;
