import React, { useState } from "react";
import "./Maindash.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Header from "../../Header/Header";
import Datepicker from "./Datepicker";
import Takeaway from "../../../Svg/Takeaway";
import Dinein from "../../../Svg/Dinein";
import Pickup from "../../../Svg/Pickup";
import Total from "../../../Svg/Total";
import CardChart from "./CardChart";
import CurrentTable from "./CurrentTable";
import MostTable from "./MostTable";
import Sidebar from "../../Sidebar/Sidebar";
import Branchs from "../../../Svg/Branchs";
import { useSelector } from "react-redux";
import items from "../../../Svg/Items";

const MainDash = () => {
  // const percentage = 66;
  const { branches, error } = useSelector((state) => state.branchData);
  console.log("bbbbbbb", branches);
  const [ToggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [ToggleOrder, setToggleOrder] = useState(1);
  const OrderTab = (index) => {
    setToggleOrder(index);
  };

  return (
    <div className="AppBack">
      <div className="Sider">
        {" "}
        <Sidebar />
      </div>
      <div className="Container">
        <Header title="Dashboard" branchList={branches} />

        <div className="DashDisplay">
          {/* Toggle Start */}
          <div className="MainDisplay">
            <div className="Toggle">
              <button
                className={ToggleState === 1 ? "Daily Active" : "Daily"}
                onClick={() => toggleTab(1)}
              >
                Daily
              </button>
              <button
                className={ToggleState === 2 ? "Weekly Active" : "Weekly"}
                onClick={() => toggleTab(2)}
              >
                Weekly
              </button>
              <button
                className={ToggleState === 3 ? "Monthly Active" : "Monthly"}
                onClick={() => toggleTab(3)}
              >
                Monthly
              </button>
            </div>
            <Datepicker />
          </div>
          {/* Toggle end */}

          {/* Cards Start */}
          <div className="Cards">
            <div className="card1">
              <div className="top">
                <h1>Take Away</h1>
                <i className="iconse">
                  {" "}
                  <Takeaway />{" "}
                </i>
              </div>
              <div className="bottom">
                <h2>125</h2>
                <span className="cardin1">
                  <CircularProgressbar value={65} text={`${65}%`} />
                </span>
              </div>
            </div>
            <div className="card2">
              <div className="top">
                <h1>Dine In</h1>
                <i className="iconse">
                  {" "}
                  <Dinein />{" "}
                </i>
              </div>
              <div className="bottom">
                <h2>125</h2>
                <span className="cardin2">
                  <CircularProgressbar value={65} text={`${65}%`} />
                </span>
              </div>
            </div>
            <div className="card3">
              <div className="top">
                <h1>Pickup-Later</h1>
                <i className="iconse">
                  {" "}
                  <Pickup />{" "}
                </i>
              </div>
              <div className="bottom">
                <h2>125</h2>
                <span className="cardin3">
                  <CircularProgressbar value={65} text={`${65}%`} />
                </span>
              </div>
            </div>
            <div className="card4">
              <div className="top">
                <h1>Total Amount</h1>
                <i className="iconse">
                  {" "}
                  <Total />{" "}
                </i>
              </div>
              <div className="bottom">
                <h2>12500</h2>
                <span className="cardin4">
                  <CircularProgressbar value={65} text={`${65}%`} />
                </span>
              </div>
            </div>
          </div>
          {/* Cards End */}

          {/* Current Orders */}

          <div className="Orders">
            <div className="current">
              <h4>Current Orders</h4>
              <div className="Toggle-Order">
                <button
                  className={ToggleOrder === 1 ? "All Activn" : "All"}
                  onClick={() => OrderTab(1)}
                >
                  All Orders
                </button>
                <button
                  className={ToggleOrder === 2 ? "Take Activn" : "Take"}
                  onClick={() => OrderTab(2)}
                >
                  Take Away
                </button>
                <button
                  className={ToggleOrder === 3 ? "Pick Activn" : "Pick"}
                  onClick={() => OrderTab(3)}
                >
                  Pick-Up
                </button>
                <button
                  className={ToggleOrder === 4 ? "Dine Activn" : "Dine"}
                  onClick={() => OrderTab(4)}
                >
                  Dine-In
                </button>
              </div>
              <div className="C-order-Table">
                <CurrentTable />
              </div>
            </div>
            <div className="MostO">
              <h4>Most Orders</h4>
              <MostTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDash;
