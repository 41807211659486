import React from 'react'
import Map from './Map.png'
import EditIcon from '../../../Svg/EditIcon'
import DeleteIcon from '../../../Svg/DeleteIcon'
import Toggle from '../UsersManagment/Toggle'

function Language() {
  return (
    <div className='Contain'>
        <h1 className="userTitle" >Choose Language</h1>
        <div className='custominp'>
          <div className='c1'>
            <img className='langico' src={Map} alt="" />
            <div className='langtlt'>
              pak
            </div>
          </div>
          <div className='c2'>
            <div className='str'>
              <div className='statuslng'>Active</div>
              <input type="radio" />
            </div>
            <div className='ED'>
            <EditIcon />
            <DeleteIcon />
            </div>
          </div>
        </div>
        <div className='morebtn'>
          <button className='add-more'>+ Add More</button>
        </div>
        <h1 className="userTitle" >RTL Option</h1>
        <div className='Most'>
          <p className='ttl'>Right To Left Text</p>
          <div className='toggling'>
            <Toggle />
          </div>
        </div>
        <div className="setbtn">
          <button className="Savebtn">
            <h6 className="Countertext">
              Save Update
            </h6>
          </button>
        </div>

        
    </div>
  )
}

export default Language;