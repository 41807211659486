import {
  GET_MERCHANT_KITCHEN_DATA_FAIL,
  GET_MERCHANT_KITCHEN_DATA_SUCCESS,
} from "../Actions/Constants";

const initialState = {
  loading: true,
  extras: [],
  ingredients: [],
};
export const MerchantKitchenData = (state = initialState, action) => {
  let { type, payload } = action;
  switch (type) {
    case GET_MERCHANT_KITCHEN_DATA_SUCCESS:
      const { extras, ingredient } = payload;
      console.log("payload kitchen", extras, ingredient);
      return {
        ...state,
        extras: [...extras],
        ingredients: [...ingredient],
        loading: false,
      };
    case GET_MERCHANT_KITCHEN_DATA_FAIL:
      console.log("fail runs");
      return {
        ...state,
        loading: true,
        extras: [],
        ingredients: [],
      };
    default:
      return {
        ...state,
      };
  }
};
