import Dashboard from "../Svg/Dashboard";
import Cafe from "../Svg/Cafe";
import Kitchen from "../Svg/Kitchen";
import Kiosk from "../Svg/Kiosk";
import KItems from "../Svg/KItems";
import Users from "../Svg/Users";
import Voucher from "../Svg/Voucher";
import Banner from "../Svg/Banner";
import Sets from "../Svg/Sets";
import Setting from "../Svg/Setting";

export const SidebarData = [
  {
    icon: <Dashboard />,
    heading: "Dashboard",
    label: "Dashboard",
    slug: "/",
  },
  {
    icon: <Cafe />,
    heading: "Cafe Management",
    label: "Cafe",
    slug: "/MainCafe",
  },
  {
    icon: <Kitchen />,
    heading: "Kitchen Management",
    label: "Kitchen",
    slug: "/Kitchen",
  },
  {
    icon: <Users />,
    heading: "Users Management",
    label: "Users Managment",
    slug: "/UsersManagment",
  },
  {
    icon: <Kiosk />,
    heading: "Kiosk Management",
    label: "Kiosk",
    slug: "/KioskManagment",
  },
  {
    icon: <KItems />,
    heading: "Kiosk Items",
    label: "Kiosk Items",
    slug: "/Items",
  },
  {
    icon: <Voucher />,
    heading: "Voucher Creation",
    label: "Voucher Creation",
    slug: "/Vouchers",
  },
  {
    icon: <Banner />,
    heading: "Banner Setting",
    label: "Banner Setting",
    slug: "/Banners",
  },
  {
    icon: <Sets />,
    heading: "Kiosk Options",
    label: "Kiosk Options",
    slug: "/KioskOptions",
  },
  {
    icon: <Setting />,
    heading: "Settings",
    label: "Settings",
    slug: "/Settings",
  },
  // {
  //     icon: <Users />,
  //     heading: "Customer List",
  //     label: "Customer List",
  //     slug: "/CustomerList",
  // },
];
