import React from "react";
import { useState, useEffect } from "react";
import Closebtn from "../../../Svg/Closebtn";
import Select from "react-select";
import { Bars } from "react-loader-spinner";

function AddVoucher({ closeModal }) {
  const Cafes = ["Admin", "CafeAdmin", "Kitchen"];

  const [voucher, setVoucher] = useState({
    vname: "",
    code: "",
    discount: "",
    expiry: "",
    cafe: "",
  });

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setVoucher({ ...voucher, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(voucher);
    setIsLoading(true);
    // do something with the form values
  };

  // Loading Funtion

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
        closeModal(false);
      }, 5000);
    }
  }, [isLoading]);

  return (
    <div className="Modalbg">
      <div className="ModalContainer" style={{ width: "350px" }}>
        <div className="closebtn">
          <button onClick={() => closeModal(false)}>
            <Closebtn />
          </button>
        </div>
        <div className="ModalTitle">Add New Voucher</div>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <div className="Modalbtn ct dr">
              <Bars
                height="50"
                width="50"
                color="#FF8C00"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <p className="loadtlt">Adding Voucher</p>
            </div>
          ) : (
            <div>
              <div className="Modalbody">
                <div className="fields">
                  <label htmlFor="vname">Voucher Name</label>
                  <input
                    value={voucher.vname}
                    onChange={handleInputs}
                    type="text"
                    id="vname"
                    name="vname"
                    placeholder="Voucher Name"
                    required
                  />
                </div>
                <div className="fields">
                  <label htmlFor="vcode">Voucher Code</label>
                  <input
                    value={voucher.code}
                    onChange={handleInputs}
                    type="text"
                    id="vcode"
                    name="code"
                    placeholder="Code"
                    required
                  />
                </div>
                <div className="fields">
                  <label htmlFor="dis">Discount %</label>
                  <input
                    value={voucher.discount}
                    onChange={handleInputs}
                    type="number"
                    id="dis"
                    name="discount"
                    placeholder="%"
                    min="0"
                    max="100"
                    required
                  />
                </div>
                <div className="fields">
                  <label htmlFor="expiry">Date Of Expiry</label>
                  <input
                    value={voucher.expiry}
                    onChange={handleInputs}
                    type="date"
                    id="expiry"
                    name="expiry"
                    placeholder="%"
                    required
                  />
                </div>
                <div className="fields">
                  <div className="selectdiv ">
                    <label htmlFor="role">
                      Only For Cafe
                      <select
                        name="cafe"
                        value={voucher.cafe}
                        onChange={handleInputs}
                      >
                        {Cafes.map((opt) => (
                          <option>{opt}</option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
              </div>
              <div className="Modalbtn">
                <button
                  id="Cancel"
                  className="btn"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn">
                  Create Category
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddVoucher;
