import Closebtn from "../../../Svg/Closebtn";
import "../Cafe/Modal.css";
import React, { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
// import Select from 'react-select';
// import Dropmenus from '../../Dropdown/Dropmenus';

function AddUser({ closeModal }) {
  const options = ["Admin", "CafeAdmin", "Kitchen"];

  // const [selected, setSelected] = useState("");

  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "",
  });

  let name, value;

  const handleInputs = (e) => {
    console.log(e);
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(user);
    setIsLoading(true);
    // validate form data and submit to server or database
  };

  useEffect(() => {
    if (isLoading) {
      // simulate a long loading process
      setTimeout(() => {
        closeModal(false);
        setIsLoading(false);
      }, 3000);
    }
  }, [isLoading]);

  return (
    <div className="Modalbg">
      <div className="ModalContainer" style={{ width: "350px" }}>
        <div className="closebtn">
          <button onClick={() => closeModal(false)}>
            <Closebtn />
          </button>
        </div>
        <div className="ModalTitle">Add New User</div>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <div className="Modalbtn ct dr">
              <Bars
                height="50"
                width="50"
                color="#FF8C00"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <p className="loadtlt">Adding User</p>
            </div>
          ) : (
            <div>
              <div className="Modalbody">
                <div className="fields">
                  <label htmlFor="Name">User Name</label>
                  <input
                    value={user.name}
                    onChange={handleInputs}
                    type="text"
                    name="name"
                    id="Name"
                    placeholder="User Name"
                    required
                  />
                </div>
                <div className="fields">
                  <label htmlFor="Email">Email</label>
                  <input
                    value={user.email}
                    onChange={handleInputs}
                    type="Email"
                    name="email"
                    id="Email"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="fields">
                  <label htmlFor="Number">Phone Number</label>
                  <input
                    value={user.phone}
                    onChange={handleInputs}
                    type="tel"
                    name="phone"
                    id="Number"
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="fields">
                  <label htmlFor="Password">Password</label>
                  <input
                    value={user.password}
                    onChange={handleInputs}
                    type="password"
                    name="password"
                    minlength="8"
                    id="Password"
                    placeholder="Password"
                    required
                  />
                </div>
                <div className="fields">
                  <div className="selectdiv ">
                    <label htmlFor="role">
                      Role of a User
                      <select
                        name="role"
                        value={user.role}
                        onChange={handleInputs}
                        required="required"
                      >
                        <option value="" disabled>
                          Select Role
                        </option>
                        {options.map((opt, key) => (
                          <option key={key}>{opt}</option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
              </div>

              <div className="Modalbtn">
                <button
                  id="Cancel"
                  className="btn"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn">
                  Create User
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddUser;
