import React from "react";
import "./Table.css";
import Plus from "../../../Svg/Plus";
import Tableoption from "../../../Svg/Tableoption";
import AddTable from "./AddTable";
import { useState } from "react";

const table = [
  { title: "01" },
  { title: "02" },
  { title: "03" },
  { title: "04" },
  { title: "05" },
  { title: "06" },
  { title: "07" },
  { title: "08" },
  { title: "09" },
];

const Table = () => {
  const [OpenModal, setOpenModal] = useState(false);

  return (
    <div className="Tableoptions">
      <button
        className="tablebutton"
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <Plus />
        <h6 className="tabletext">Add Table</h6>
      </button>

      <div className="tablesection">
        {table.map((tal, key) => {
          return (
            <div key={key} className="table-box">
              <i className="tableoption-icon">
                {" "}
                <Tableoption />{" "}
              </i>
              <p className="numbering"> {tal.title} </p>
            </div>
          );
        })}
      </div>
      <button className="Savebutton">
        <h6 className="tabletext">Save Update</h6>
      </button>
      {OpenModal && <AddTable closeModal={setOpenModal} />}
    </div>
  );
};

export default Table;
