import React from "react";

function Ordermode() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="14"
      fill="none"
      viewBox="0 0 10 14"
    >
      <path
        fill="red"
        d="M4.444 13.748a.626.626 0 01-.619-.694l.481-4.43H.626a.625.625 0 01-.519-.975L5.037.275A.625.625 0 015.77.038a.625.625 0 01.406.624l-.481 4.462h3.68a.625.625 0 01.519.975l-4.93 7.373a.626.626 0 01-.52.276z"
      ></path>
    </svg>
  );
}

export default Ordermode;
