import React, { useState } from 'react'
import Header from '../../Header/Header'
import Orderinfobtn from './Orderinfobtn'
import './Kitchen.css'
import Information from '../../../Svg/Information'
import Sidebar from '../../Sidebar/Sidebar';

const Kitchen = () => {

    const [ToggleOrder,setToggleOrder] = useState(1);
    const OrderTab = (index) => {
    setToggleOrder(index);
  }

  return (
    <div className="AppBack">
    <div className='Sider' > <Sidebar/></div>
    <div className='Container'>
        <Header title="Kitchen Managment" />
        <div className="Mainkitchen">
            <div className='ordersinfo'>
                <Orderinfobtn />
            </div>
            <div className='ordersTable'>
                <div className='Toggle-Order-2'> 
                <button 
                className={ToggleOrder === 1 ? "All Activn" : "All" }
                onClick={() => OrderTab(1)}
                >All Orders</button>
                <button 
                className={ToggleOrder === 2 ? 'Take Activn' : 'Take' }
                onClick={() => OrderTab(2)}
                >Take Away</button>
                <button 
                className={ToggleOrder === 3 ? 'Pick Activn' : 'Pick' }
                onClick={() => OrderTab(3)}
                >Pick-Up</button>
                <button 
                className={ToggleOrder === 4 ? 'Dine Activn' : 'Dine' }
                onClick={() => OrderTab(4)}
                >Dine-In</button>
                </div>
            <div className='Maindsection'>
                <div className="ordersection">
                    <div className="orderrows">
                        <div className='Heading1'>
                            <span className='row-text'>Order Placed</span>
                            <span className='ordercal1'>41</span>
                        </div>
                        <div className="ordersstring">
                            <div className="ordercard">
                                <div className="ordertitle">
                                    <span className='ordernumber'>#12345644132</span>
                                    <i className='myicon'> <Information /> </i>
                                </div>
                                <span className="ordertype">Take Away</span>
                                <span className="rate">$172</span>
                                <div className="timing-table">
                                    <span className="timing">Pickup-Time : 16:12</span>
                                    <span className="tabling">Table No. 5</span>
                                </div>
                                <div className="btns">
                                    <button className="cancel">Cancel Order</button>
                                    <button className="start">Start Preparing</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                        
                    <div className="orderrows">
                        <div className='Heading2'>
                            <span className='row-text'>Preparing</span>
                            <span className='ordercal2'>41</span>
                        </div>
                        <div className="ordersstring">
                            <div className="ordercard">
                                <div className="ordertitle">
                                    <span className='ordernumber'>#12345644132</span>
                                    <i className='myicon'> <Information /> </i>
                                </div>
                                <span className="ordertype">Take Away</span>
                                <span className="rate">$172</span>
                                <div className="timing-table">
                                    <span className="timing">Pickup-Time : 16:12</span>
                                    <span className="tabling">Table No. 5</span>
                                </div>
                                <div className="btns">
                                    <button className="cancel">Cancel Order</button>
                                    <button className="start">Mark As Ready</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="orderrows">
                        <div className='Heading3'>
                            <span className='row-text'>Ready</span>
                            <span className='ordercal3'>41</span>
                        </div>
                        <div className="ordersstring">
                            <div className="ordercard">
                                <div className="ordertitle">
                                    <span className='ordernumber'>#12345644132</span>
                                    <i className='myicon'> <Information /> </i>
                                </div>
                                <span className="ordertype">Take Away</span>
                                <span className="rate">$172</span>
                                <div className="timing-table">
                                    <span className="timing">Pickup-Time : 16:12</span>
                                    <span className="tabling">Table No. 5</span>
                                </div>
                                <div className="btns">
                                    <button className="cancel">Cancel Order</button>
                                    <button className="start">Start Preparing</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="orderrows">
                        <div className='Heading4'>
                            <span className='row-text'>Delivered</span>
                            <span className='ordercal4'>41</span>
                        </div>
                        <div className="ordersstring">
                            <div className="ordercard">
                                <div className="ordertitle">
                                    <span className='ordernumber'>#12345644132</span>
                                    <i className='myicon'> <Information /> </i>
                                </div>
                                <span className="ordertype">Take Away</span>
                                <span className="rate">$172</span>
                                <div className="timing-table">
                                    <span className="timing">Pickup-Time : 16:12</span>
                                    <span className="tabling">Table No. 5</span>
                                </div>
                                <div className="btns">
                                    <button className="cancel">Cancel Order</button>
                                    <button className="start">Start Preparing</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            </div>
        </div>
    </div>
    </div>
  )
}

export default Kitchen;